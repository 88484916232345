import { useMediaQuery } from "@mui/material";

function DashboardNavItem({ text, icon, selected, callback }) {
  function handleClick() {
    if (callback) {
      callback(text);
    }
  }

  const matchesMax768px = useMediaQuery("(max-width: 768px)");

  return (
    <div
      className={
        selected
          ? `flex items-center justify-start cursor-pointer w-40 py-6 px-2 h-9 select-none text-sm zinc-900 rounded-xl bg-gray-100 text-left max-[600px]:!mr-8
          ${matchesMax768px && "text-xs py-9 pr-4 rounded-lg"}
          `
          : `flex items-center justify-start cursor-pointer w-40 py-6 px-2 h-9 select-none text-sm zinc-900 text-left ${
              matchesMax768px && "text-xs py-9 pr-4"
            }`
      }
      onClick={handleClick}
    >
      <div className="pr-5">{icon}</div>
      {text}
    </div>
  );
}

export default DashboardNavItem;
