import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const createOrderPayment = async (data) => {
  try {
    const config = {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        }
    }
    const response = await axios.post(`${API_URL}/shop/orders/payment/`, data, config);
    return response.data;
  } catch (error) {
    // Handle or throw error
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Create order error:", error.response);
      throw error;
    }
  }
};

export const placeOrder = async (data) => {
    try {
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            }
        }
        const response = await axios.post(`${API_URL}/shop/orders/create/`, data, config);
        return response.data;
        } catch (error) {
        // Handle or throw error
        if (error.response && error.response.status === 500) {
          console.error("500 Internal Server Error");
          throw error;
        } else {
          console.error("Place order error:", error.response);
          throw error;
        }
    } 
};