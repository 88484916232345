import { useNavigate } from "react-router-dom";

export default function SecondaryButton(props) {
  const navigate = useNavigate();

  const handleClick = (e) => {
    if (props.href) {
      e.preventDefault();
      navigate(props.href);
    }
    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <button
      state={`${props.state}`}
      to={`${props.to}`}
      href={`${props.href}`}
      id={`${props.id}`}
      disabled={props.disabled}
      onClick={handleClick}
      type={`${props.type}`}
      className={`bg-blue hover:bg-blue-dark3 hover:shadow-buttonHover active:shadow-buttonClick flex justify-center items-center text-base !font-semibold px-4 py-2 text-white rounded-3xl shadow-button whitespace-nowrap w-min ${props.className} `}
    >
      {props.children}
    </button>
  );
}
