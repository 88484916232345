// TosModal.js
import React from "react";
import classes from "./TermsModal.module.css";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";

const TermsModal = ({ isOpen, onAccept, onClose }) => {
  return (
    <div className={`${classes.tos_modal} ${isOpen ? "open" : ""}`}>
      <div className={classes.tos_modal_overlay} onClick={onClose}></div>

      <div className={classes.tos_modal_content}>
        <h3 className="text-2xl font-[700] text-center text-orange">
          Scroll down to read our Terms of Service and accept to proceed
        </h3>
        <hr className="border-2 !mb-2"></hr>
        <h3 className="text-2xl font-bold mb-2">
          Terms of Service - Fundraising Representation Agreement
        </h3>
        <h4 className="text-lg mb-2">
          By selecting accept for the terms of agreement, you agree to accept
          this as a binding agreement between Sponsor a Pet and the Nonprofit
          Organization
        </h4>
        <h4 className="text-xl font-semibold mb-2">
          Identification of Parties
        </h4>
        <p>SPONSOR A PET INC. ("SPONSOR A PET"), a Delaware corporation.</p>
        <p>
          Nonprofit Organization ("NP"), acting through authorized officers.
        </p>

        <h4 className="text-xl font-semibold mb-2">Recitals:</h4>
        <p>
          NP, a nonprofit, desires SPONSOR A PET’s fundraising services for its
          charitable animal-related causes. SPONSOR A PET agrees to fulfill this
          role.
        </p>

        <h4 className="text-xl font-semibold mb-2">Term of Agreement</h4>
        <p>
          This agreement is effective from the above date and will continue
          until terminated as outlined herein.
        </p>

        <h4 className="text-xl font-semibold mb-2">Cancellation</h4>
        <p>
          NP shall have the right to cancel this Agreement without cost, penalty
          or liability by delivering a written notice of cancellation (the
          "Cancellation Notice") to SPONSOR A PET. The Cancellation Notice shall
          be sufficient if it indicates that NP does not intend to be bound by
          this Agreement. The cancellation will take effect 7 days after notice.
          All campaigns must be closed at the end of 7 days. Any funds collected
          after the Cancellation Notice during those 7 days shall be deemed to
          be held in trust for the benefit of NP without any deduction for costs
          or expenses. NP shall not be entitled to recover all funds collected
          after the effective date of the Cancellation Notice. In the event of
          termination, NP shall be liable for any compensation or fees due and
          payable for any services provided by SPONSOR A PET up to and including
          the effective date of the Cancellation Notice. SPONSOR A PET may
          immediately terminate this Agreement for any reason. All cancellation
          notices shall be delivered in writing.
        </p>

        <h4 className="text-xl font-semibold mb-2">
          Description of Obligations
        </h4>
        <p>
          SPONSOR A PET is authorized to use NP’s name, logo, and goodwill for
          fundraising promotions on its website and all other forms of
          communications.
        </p>

        <h4 className="text-xl font-semibold mb-2">Fundraising Methods</h4>
        <p>
          All fundraising activities will be conducted via SPONSOR A PET's
          website by NP, SPONSOR A PET, and their respective agents and
          representatives.
        </p>

        <h4 className="text-xl font-semibold mb-2">Control of Funds</h4>
        <p>
          SPONSOR A PET must deposit or deliver contributions to NP within five
          working days. Contributions are the property of NP minus agreed-upon
          fees.
        </p>

        <h4 className="text-xl font-semibold mb-2">Fees and Payments</h4>
        <ul>
          <li>
            Based on SPONSOR A PET's terms and conditions, any funds received by
            SPONSOR A PET on behalf of NP's receipt shall be distributed to NP
            at the rate of 92.5% of the monetary contributions received, and
            7.5% + all processing and transaction fees will be retained by
            SPONSOR A PET to cover the processing and transaction costs for
            transacting NP's monetary donations.
          </li>
          <li>
            All other income collected by SPONSOR A PET (specifically PetStar,
            tip income and transaction fees) do not apply to NP's payout formula
            as listed above.
          </li>
          <li>
            All product sales (as well as associated tip income and transaction
            fees) related to donations inkind collected and processed by SPONSOR
            A PET on behalf of NP do not apply to the monetary donation formula
            as listed above.
          </li>
        </ul>

        <h4 className="text-xl font-semibold mb-2">
          Acknowledgements and Receipts
        </h4>
        <p>
          SPONSOR A PET is authorized to issue donation receipts and
          acknowledgements on NP’s behalf.
        </p>

        <h4 className="text-xl font-semibold mb-2">Indemnification</h4>
        <p>
          NP agrees to indemnify SPONSOR A PET from any claims, damages, lawyer
          fees or monetary losses arising from SPONSOR A PET's obligations under
          this Agreement.
        </p>

        <h4 className="text-xl font-semibold mb-2">
          Governing Law & Jurisdiction
        </h4>
        <p>
          Governed by the laws of Delaware. Both parties consent to exclusive
          jurisdiction in the State of Delaware.
        </p>

        <h4 className="text-xl font-semibold mb-2">Amendments</h4>
        <p>
          Amendments must be in writing, signed by both parties to be effective.
        </p>

        <h4 className="text-xl font-semibold mb-2">Successors and Assigns</h4>
        <p>
          This Agreement binds successors, assigns, and legal representatives.
          SPONSOR A PET can assign rights and obligations with prior consent
          from NP.
        </p>

        <h4 className="text-xl font-semibold mb-2">Notices</h4>
        <p>
          Must be in writing, sent by certified mail, email, or overnight
          courier to addresses on record.
        </p>

        <h4 className="text-xl font-semibold mb-2">Miscellaneous</h4>
        <ul>
          <li>Waiver: No waiver effective unless in writing.</li>
          <li>
            Severability: Invalid provisions will be renegotiated or excluded.
          </li>
          <li>
            Attorneys' Fees: Prevailing party in any dispute entitled to legal
            fees.
          </li>
        </ul>
        <hr className="border-2 !my-2"></hr>
        <div className={classes.tos_modal_buttons}>
          <SecondaryButton className="mt-4" onClick={onAccept}>
            Accept
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;
