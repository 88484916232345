import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const sendEmail = async (emailData, numericSelection, campaignId, nonprofitId) => {
  try {
    const data = {
			'name': emailData.name,
			'subject': emailData.subject,
			'body': emailData.body,
			'nonprofit': nonprofitId,
			'campaign': campaignId,
			'donor_subset': numericSelection,
      'custom_email': emailData.custom_email
    }
    const config = {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
				'Content-Type': 'application/json'
      }
    }
    const response = await axios.post(`${API_URL}/emails/send-emails/`, data, config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Test Email error:", error.response);
      throw error;
    }
  }
};
