import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PrimaryButton from "../shared/Buttons/PrimaryButton";
import SecondaryButton from "../shared/Buttons/SecondaryButton";
import { State, City } from "country-state-city";
import { validateEmail } from "../shared/Utils/ValidateEmail";
import { fosterSubmit } from "../../api/FosterSubmit";
import LoginSignupModal from "../shared/LoginSignupModal/LoginSignupModal";

// This is the Foster Interest Page
// This page sends form data to the backend in order to submit a user to be a foster
// Requires a user to be signed up or logged in

const FosterInterestPage = () => {
  const userData = useSelector((state) => state.userData.userData);
  const [localUserData, setLocalUserData] = useState(null);
  const [formData, setFormData] = useState({
    user: "", // required
    full_name: "", // required
    email: "", // required
    phone: "", // required
    street: "", // required
    city: "", // required
    state: "", // required
    country: "US",
    zip_code: "", // required
    older_than_18: false,
    has_drivers_license: false,
    license: "",
    rent_or_own: "", // required
    rent_allow_dogs: false, // required
    num_adults: 0,
    num_children: 0,
    age_of_children: "",
    allergies: false, // required
    allergies_info: "",
    yard_info: "",
    landlord: "",
    current_pets: false, // required
    current_pets_info: "",
    pets_health: false, // required
    vaccinations: false, // required
    medical_issues: "", // required
    vet_info: "",
    previous_foster: false, // required
    past_experiences: "",
    work_hours: 0,
    max_foster_time: "", // required
    pet_separation: false, // required
    foster_stay: "", // required
    foster_bed: "",
    transporting_foster: false,
    reason_to_foster: "",
    additional_info: "",
  });
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [loginOrSignup, setLoginOrSignup] = useState("");
  const [formSuccess, setFormSuccess] = useState(false);

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      const parsedUserData = JSON.parse(userData);
      setLocalUserData(parsedUserData);
    }
  }, [userData]);

  useEffect(() => {
    if (localUserData) {
      setFormData((prevData) => ({ ...prevData, user: localUserData.id }));
    }
  }, [localUserData]);

  useEffect(() => {
    const countryCode = "US";
    const countryStates = State.getStatesOfCountry(countryCode);
    setStates(countryStates);
  }, []);

  const openModal = (type) => {
    setLoginOrSignup(type);
    setModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setModalOpen(false);
    document.body.style.overflow = "";
  };

  const handleFormSuccess = (success) => {
    if (success) {
      window.location.reload();
    }
  };

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    setFormData({ ...formData, state: selectedState, city: "" });

    // Fetch cities based on the selected state
    const stateCities = City.getCitiesOfState("US", selectedState);
    setCities(stateCities);
  };

  const handleCityChange = (e) => {
    setFormData({ ...formData, city: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrorMsg("");
    setSuccessMsg("");

    // Validation for the email format
    if (!validateEmail(formData.email)) {
      setErrorMsg("Please enter a valid email address.");
      setSuccessMsg("");
      return;
    }

    // Testing for required inputs
    if (
      !formData.full_name ||
      !formData.phone ||
      !formData.email ||
      !formData.street ||
      !formData.city ||
      !formData.zip_code ||
      !formData.rent_or_own ||
      !formData.medical_issues ||
      !formData.max_foster_time ||
      !formData.foster_stay
    ) {
      setErrorMsg("Please fill in all required fields.");
      return;
    }

    try {
      const response = await fosterSubmit(formData);
      if (response) {
        setSuccessMsg("Your foster interest has been submitted successfully.");
        setErrorMsg("");

        setFormData({
          user: "",
          full_name: "",
          email: "",
          phone: "",
          street: "",
          city: "",
          state: "",
          country: "US",
          zip_code: "",
          older_than_18: false,
          has_drivers_license: false,
          license: "",
          rent_or_own: "rent",
          rent_allow_dogs: false,
          num_adults: 0,
          num_children: 0,
          age_of_children: "",
          allergies: false,
          allergies_info: "",
          yard_info: "",
          landlord: "",
          current_pets: false,
          current_pets_info: "",
          pets_health: false,
          vaccinations: false,
          medical_issues: "",
          vet_info: "",
          previous_foster: false,
          past_experiences: "",
          work_hours: 0,
          max_foster_time: "",
          pet_separation: false,
          foster_stay: "",
          foster_bed: "",
          transporting_foster: "",
          reason_to_foster: "",
          additional_info: "",
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setErrorMsg("Internal server error. Please try again later.");
      } else {
        setErrorMsg(
          "An error occurred while submitting the form. Please try again."
        );
      }
      setSuccessMsg("");
    }
  };

  return (
    <div className="w-full mt-24">
      {/* Header */}
      <section className="flex flex-col lg:flex-row justify-center items-center gap-16 py-16 px-8 2xl:mx-36">
        <img
          className="w-full sm:w-2/3 lg:w-1/3"
          src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/foster-interest-page/Cat+and+dog+image.png"
        />
        <div className="text-left w-full lg:w-1/2 xl:w-full">
          <h1 className="!font-normal !text-6xl text-blue mb-12 lg:mb-20">
            Animal Foster Form
          </h1>
          <div className="font-normal !text-xl flex flex-col gap-4">
            <p className="!text-xl">
              Thank you for considering fostering a pet! Fosters are the
              backbone of rescue organizations, providing a safe and loving
              environment while pets wait for their forever homes.
            </p>
            <p className="!text-xl">
              By fostering, you're not only changing the life of one dog, cat or
              pet lost in the system but also creating space for another to be
              rescued.
            </p>
            <p className="!text-xl">
              Your kindness makes an incredible difference in their journey,
              simply put, fosters save lives and rescues could not successfully
              operate without their generosity.
            </p>
          </div>
        </div>
      </section>

      <section className="w-full bg-white">
        <div className="bg-blue py-16 px-4 xl:px-16 2xl:px-48 rounded-3xl m-8 2xl:mx-36">
          <p className="text-white">
            We are dedicated to supporting our nonprofit partners by
            creating a centralized database for individuals interested in
            fostering animals. This resource connects people eager to help with
            the organizations that need them most, streamlining the fostering
            process for both volunteers and nonprofits. By enabling nonprofit partners
            to easily identify and reach out to potential fosters, we aim to
            expand community support for animals in need, offering a
            straightforward pathway for fostering connections that bring animals
            into safe, temporary homes. This initiative strengthens our network
            of nonprofit partners and fosters, creating lasting impact for animals and
            the people who care for them.
          </p>
        </div>
      </section>

      {/* Form */}
      {localUserData ? (
        <section className="w-full bg-white">
          <form onSubmit={handleSubmit}>
            {/* Basic info fields */}
            <div className="bg-blue py-16 px-4 xl:px-16 2xl:px-48 rounded-3xl m-8 2xl:mx-36">
              <h1 className="text-white font-normal text-5xl text-center">
                Animal Foster Inquiry
              </h1>

              <div className="py-8">
                <h2 className="text-white font-roboto font-bold text-xl sm:text-2xl text-left my-4">
                  Enter Your Information:
                </h2>
                <div className="my-4">
                  <label className="text-white font-roboto font-normal text-lg sm:text-xl text-left !w-full block mb-2">
                    Full name{" "}
                    <span className="text-grey-dark1 text-sm font-roboto font-normal">
                      (Required)
                    </span>
                  </label>
                  <input
                    className="font-roboto rounded-3xl w-full pl-8 focus:outline-blue h-12"
                    required
                    type="text"
                    maxLength="100"
                    value={formData.full_name}
                    onChange={(e) =>
                      setFormData({ ...formData, full_name: e.target.value })
                    }
                  />
                </div>

                <div className="my-4">
                  <label className="text-white font-roboto font-normal text-lg sm:text-xl text-left !w-full block mb-2">
                    Email Address{" "}
                    <span className="text-grey-dark1 text-sm font-roboto font-normal">
                      (Required)
                    </span>
                  </label>
                  <input
                    required
                    className="font-roboto rounded-3xl w-full pl-8 focus:outline-blue h-12"
                    type="email"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </div>

                <div className="my-4">
                  <label className="text-white font-roboto font-normal text-lg sm:text-xl text-left !w-full block mb-2">
                    Phone Number{" "}
                    <span className="text-grey-dark1 text-sm font-roboto font-normal">
                      (Required)
                    </span>
                  </label>
                  <input
                    required
                    className="font-roboto rounded-3xl w-full pl-8 focus:outline-blue h-12"
                    type="tel"
                    maxLength="10"
                    value={formData.phone}
                    onChange={(e) =>
                      setFormData({ ...formData, phone: e.target.value })
                    }
                  />
                </div>

                <div className="my-4">
                  <label className="text-white font-roboto font-normal text-lg sm:text-xl text-left !w-full block mb-2">
                    Home Address{" "}
                    <span className="text-grey-dark1 text-sm font-roboto font-normal">
                      (Required)
                    </span>
                  </label>
                  <input
                    required
                    className="font-roboto rounded-3xl w-full pl-8 focus:outline-blue h-12"
                    type="text"
                    value={formData.street}
                    maxLength="100"
                    onChange={(e) =>
                      setFormData({ ...formData, street: e.target.value })
                    }
                  />
                </div>

                <div className="flex flex-row flex-wrap lg:flex-nowrap justify-start items-center gap-8 my-4">
                  <div className="">
                    <label className="text-white font-roboto font-normal text-lg sm:text-xl text-left !w-full block mb-2">
                      State{" "}
                      <span className="text-grey-dark1 text-sm font-roboto font-normal">
                        (Required)
                      </span>
                    </label>
                    <select
                      required
                      className="font-roboto rounded-3xl focus:outline-blue h-12 w-64 px-4"
                      value={formData.state}
                      onChange={handleStateChange}
                    >
                      <option value="">Select a State</option>
                      {states.map((state) => (
                        <option
                          className="font-roboto"
                          key={state.isoCode}
                          value={state.isoCode}
                        >
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="">
                    <label className="text-white font-roboto font-normal text-lg sm:text-xl text-left !w-full block mb-2">
                      City{" "}
                      <span className="text-grey-dark1 text-sm font-roboto font-normal">
                        (Required)
                      </span>
                    </label>
                    <select
                      required
                      className="font-roboto rounded-3xl focus:outline-blue h-12 w-64 px-4"
                      value={formData.city}
                      onChange={handleCityChange}
                      disabled={!formData.state}
                    >
                      <option value="">Select a City</option>
                      {cities.map((city) => (
                        <option
                          className="font-roboto"
                          key={city.name}
                          value={city.name}
                        >
                          {city.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="">
                    <label className="text-white font-roboto font-normal text-lg sm:text-xl text-left !w-full block mb-2">
                      Postal Code/Zip{" "}
                      <span className="text-grey-dark1 text-sm font-roboto font-normal">
                        (Required)
                      </span>
                    </label>
                    <input
                      className="font-roboto rounded-3xl w-64 pl-8 focus:outline-blue h-12 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      value={formData.zip_code}
                      type="text"
                      maxLength="5"
                      onChange={(e) =>
                        setFormData({ ...formData, zip_code: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="px-4 sm:px-16 xl:px-48 2xl:px-72 py-8 w-full">
              {/* Household questions */}
              <div className="flex flex-row w-full justify-start">
                <div className="flex flex-col items-center">
                  <div className="rounded-full w-8 h-8 bg-gray-300 mb-0"></div>
                  <div className="flex-grow w-1 bg-gray-300"></div>
                </div>
                <div className="flex flex-col w-full pb-12 ml-8">
                  {[
                    {
                      question: "Are you 18 or older?",
                      stateKey: "older_than_18",
                      options: ["Yes", "No"],
                    },
                    {
                      question: `Do you have a current & valid driver's license from ${formData.state}?`,
                      stateKey: "has_drivers_license",
                      options: ["Yes", "No"],
                    },
                  ].map(({ question, stateKey, options }) => (
                    <div key={stateKey} className="w-full my-2.5">
                      <label className="block text-left w-full mb-2">
                        {question}
                      </label>
                      <div className="flex flex-row items-center gap-8">
                        {options.map((option, index) => (
                          <div key={option} className="flex items-center">
                            <input
                              type="radio"
                              required
                              className="rounded-3xl w-6 h-6 focus:outline-blue mr-2 cursor-pointer "
                              value={option.toLowerCase()}
                              id={`${stateKey}-${option.toLowerCase()}`}
                              checked={formData[stateKey] === (index === 0)}
                              onChange={() =>
                                setFormData({
                                  ...formData,
                                  [stateKey]: index === 0,
                                })
                              }
                            />
                            <label
                              htmlFor={`${stateKey}-${option.toLowerCase()}`}
                              className="cursor-pointer"
                            >
                              {option}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}

                  {/* Additional Input for License Number */}
                  {formData.has_drivers_license && (
                    <div className="w-full my-2.5">
                      <label className="block text-left w-full mb-2">
                        What's your driver’s license number?
                      </label>
                      <input
                        type="text"
                        className="font-roboto rounded-3xl w-full pl-8 focus:outline-blue h-12"
                        required
                        maxLength="20"
                        value={formData.license}
                        onChange={(e) =>
                          setFormData({ ...formData, license: e.target.value })
                        }
                      />
                    </div>
                  )}
                </div>
              </div>

              {/* Additional info */}
              <div className="flex flex-row w-full justify-start">
                <div className="flex flex-col items-center">
                  <div className="rounded-full w-8 h-8 bg-gray-300 mb-0"></div>
                  <div className="flex-grow w-1 bg-gray-300"></div>
                </div>
                <div className="flex flex-col w-full pb-12 ml-8">
                  <h1 className="text-left !font-normal !text-4xl mb-8">
                    Your Household
                  </h1>

                  <div className="w-full my-2.5 text-left">
                    <label className="block text-left w-full mb-2">
                      Do you rent or own your home?{" "}
                      <span className="text-grey-dark1 text-sm font-roboto font-normal">
                        (Required)
                      </span>
                    </label>
                    <select
                      value={formData.rent_or_own}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          rent_or_own: e.target.value,
                        })
                      }
                      required
                      className="font-roboto border border-stone-400 rounded-3xl w-64 pl-8 focus:outline-blue h-12 bg-white"
                    >
                      <option value="" disabled className="font-roboto">
                        Select an option
                      </option>
                      <option value="rent" className="font-roboto">
                        Rent
                      </option>
                      <option value="own" className="font-roboto">
                        Own
                      </option>
                    </select>
                  </div>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      If renting, does your lease allow pets?{" "}
                      <span className="text-grey-dark1 text-sm font-roboto font-normal">
                        (Required)
                      </span>
                    </label>
                    <div className="flex items-center gap-4">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          required
                          className="rounded-3xl w-6 h-6 focus:outline-blue mr-2 cursor-pointer"
                          value="yes"
                          id="rent-allow-dogs-yes"
                          checked={formData.rent_allow_dogs === true}
                          onChange={() =>
                            setFormData({ ...formData, rent_allow_dogs: true })
                          }
                        />
                        <label
                          htmlFor="rent-allow-dogs-yes"
                          className="cursor-pointer"
                        >
                          Yes
                        </label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="radio"
                          required
                          className="rounded-3xl w-6 h-6 focus:outline-blue mr-2 cursor-pointer"
                          value="no"
                          id="rent-allow-dogs-no"
                          checked={formData.rent_allow_dogs === false}
                          onChange={() =>
                            setFormData({ ...formData, rent_allow_dogs: false })
                          }
                        />
                        <label
                          htmlFor="rent-allow-dogs-no"
                          className="cursor-pointer"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      How many adults live in your home?
                    </label>
                    <input
                      type="number"
                      value={formData.num_adults}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          num_adults: parseInt(e.target.value) || 0,
                        })
                      }
                      className="font-roboto rounded-3xl w-full pl-8 focus:outline-blue h-12 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    />
                  </div>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      How many children live in your home?
                    </label>
                    <input
                      type="number"
                      value={formData.num_children}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          num_children: parseInt(e.target.value) || 0,
                        })
                      }
                      className="font-roboto rounded-3xl w-full pl-8 focus:outline-blue h-12 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    />
                  </div>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      What are their ages?
                    </label>
                    <input
                      type="text"
                      maxLength="100"
                      value={formData.age_of_children}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          age_of_children: e.target.value,
                        })
                      }
                      className="font-roboto rounded-3xl w-full pl-8 focus:outline-blue h-12"
                    />
                  </div>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      Does anyone in your household have allergies that could
                      affect fostering of pets?{" "}
                      <span className="text-grey-dark1 text-sm font-roboto font-normal">
                        (Required)
                      </span>
                    </label>

                    <div className="flex items-center gap-4">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          required
                          className="rounded-3xl w-6 h-6 focus:outline-blue mr-2 cursor-pointer"
                          value="yes"
                          id="allergies-yes"
                          checked={formData.allergies === true}
                          onChange={() =>
                            setFormData({ ...formData, allergies: true })
                          }
                        />
                        <label
                          htmlFor="allergies-yes"
                          className="cursor-pointer"
                        >
                          Yes
                        </label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="radio"
                          required
                          className="rounded-3xl w-6 h-6 focus:outline-blue mr-2 cursor-pointer"
                          value="no"
                          id="allergies-no"
                          checked={formData.allergies === false}
                          onChange={() =>
                            setFormData({ ...formData, allergies: false })
                          }
                        />
                        <label
                          htmlFor="allergies-no"
                          className="cursor-pointer"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      If so, please explain what allergies
                    </label>
                    <input
                      type="text"
                      maxLength="100"
                      value={formData.allergies_info}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          allergies_info: e.target.value,
                        })
                      }
                      className="font-roboto rounded-3xl w-full pl-8 focus:outline-blue h-12"
                    />
                  </div>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      Describe your yard (if you don’t have one, proceed without
                      filling this section)
                    </label>
                    <textarea
                      value={formData.yard_info}
                      onChange={(e) =>
                        setFormData({ ...formData, yard_info: e.target.value })
                      }
                      rows="6"
                      maxLength="500"
                      className="font-roboto rounded-3xl w-full pl-8 pt-4 focus:outline-blue border border-stone-400"
                    />
                  </div>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      Landlord’s Phone # and name (if you rent)
                    </label>
                    <input
                      type="text"
                      maxLength="100"
                      value={formData.landlord}
                      onChange={(e) =>
                        setFormData({ ...formData, landlord: e.target.value })
                      }
                      className="font-roboto rounded-3xl w-full pl-8 focus:outline-blue h-12"
                    />
                  </div>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      Do you currently own any pets?{" "}
                      <span className="text-grey-dark1 text-sm font-roboto font-normal">
                        (Required)
                      </span>
                    </label>

                    <div className="flex items-center gap-4">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          required
                          className="rounded-3xl w-6 h-6 focus:outline-blue mr-2 cursor-pointer"
                          value="yes"
                          id="current-pets-yes"
                          checked={formData.current_pets === true}
                          onChange={() =>
                            setFormData({ ...formData, current_pets: true })
                          }
                        />
                        <label
                          htmlFor="current-pets-yes"
                          className="cursor-pointer"
                        >
                          Yes
                        </label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="radio"
                          required
                          className="rounded-3xl w-6 h-6 focus:outline-blue mr-2 cursor-pointer"
                          value="no"
                          id="current-pets-no"
                          checked={formData.current_pets === false}
                          onChange={() =>
                            setFormData({ ...formData, current_pets: false })
                          }
                        />
                        <label
                          htmlFor="current-pets-no"
                          className="cursor-pointer"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      If yes, please list breeds & ages
                    </label>
                    <input
                      type="text"
                      maxLength="300"
                      value={formData.current_pets_info}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          current_pets_info: e.target.value,
                        })
                      }
                      className="font-roboto rounded-3xl w-full pl-8 focus:outline-blue h-12"
                    />
                  </div>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      Are these animals spayed/neutered?{" "}
                      <span className="text-grey-dark1 text-sm font-roboto font-normal">
                        (Required)
                      </span>
                    </label>

                    <div className="flex items-center gap-4">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          required
                          className="rounded-3xl w-6 h-6 focus:outline-blue mr-2 cursor-pointer"
                          value="yes"
                          id="spayed-neutered-yes"
                          checked={formData.pets_health === true}
                          onChange={() =>
                            setFormData({ ...formData, pets_health: true })
                          }
                        />
                        <label
                          htmlFor="spayed-neutered-yes"
                          className="cursor-pointer"
                        >
                          Yes
                        </label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="radio"
                          required
                          className="rounded-3xl w-6 h-6 focus:outline-blue mr-2 cursor-pointer"
                          value="no"
                          id="spayed-neutered-no"
                          checked={formData.pets_health === false}
                          onChange={() =>
                            setFormData({ ...formData, pets_health: false })
                          }
                        />
                        <label
                          htmlFor="spayed-neutered-no"
                          className="cursor-pointer"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      Are your pets current on vaccinations?{" "}
                      <span className="text-grey-dark1 text-sm font-roboto font-normal">
                        (Required)
                      </span>
                    </label>

                    <div className="flex items-center gap-4">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          required
                          className="rounded-3xl w-6 h-6 focus:outline-blue mr-2 cursor-pointer"
                          value="yes"
                          id="vaccinations-yes"
                          checked={formData.vaccinations === true}
                          onChange={() =>
                            setFormData({ ...formData, vaccinations: true })
                          }
                        />
                        <label
                          htmlFor="vaccinations-yes"
                          className="cursor-pointer"
                        >
                          Yes
                        </label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="radio"
                          required
                          className="rounded-3xl w-6 h-6 focus:outline-blue mr-2 cursor-pointer"
                          value="no"
                          id="vaccinations-no"
                          checked={formData.vaccinations === false}
                          onChange={() =>
                            setFormData({ ...formData, vaccinations: false })
                          }
                        />
                        <label
                          htmlFor="vaccinations-no"
                          className="cursor-pointer"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      List any medical issues with your current pets (if you
                      don't have pets, put N/A){" "}
                      <span className="text-grey-dark1 text-sm font-roboto font-normal">
                        (Required)
                      </span>
                    </label>
                    <input
                      type="text"
                      maxLength="300"
                      value={formData.medical_issues}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          medical_issues: e.target.value,
                        })
                      }
                      className="font-roboto rounded-3xl w-full pl-8 focus:outline-blue h-12"
                    />
                  </div>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      Please provide your veterinarian's name & phone #
                    </label>
                    <input
                      type="text"
                      maxLength="200"
                      value={formData.vet_info}
                      onChange={(e) =>
                        setFormData({ ...formData, vet_info: e.target.value })
                      }
                      className="font-roboto rounded-3xl w-full pl-8 focus:outline-blue h-12"
                    />
                  </div>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      Have you ever fostered animals before?{" "}
                      <span className="text-grey-dark1 text-sm font-roboto font-normal">
                        (Required)
                      </span>
                    </label>

                    <div className="flex items-center gap-4">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          required
                          className="rounded-3xl w-6 h-6 focus:outline-blue mr-2 cursor-pointer"
                          value="yes"
                          id="previous-foster-yes"
                          checked={formData.previous_foster === true}
                          onChange={() =>
                            setFormData({ ...formData, previous_foster: true })
                          }
                        />
                        <label
                          htmlFor="previous-foster-yes"
                          className="cursor-pointer"
                        >
                          Yes
                        </label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="radio"
                          required
                          className="rounded-3xl w-6 h-6 focus:outline-blue mr-2 cursor-pointer"
                          value="no"
                          id="previous-foster-no"
                          checked={formData.previous_foster === false}
                          onChange={() =>
                            setFormData({ ...formData, previous_foster: false })
                          }
                        />
                        <label
                          htmlFor="previous-foster-no"
                          className="cursor-pointer"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      Please describe your level of animal handling experience
                      with Kittens, Cats, Dogs & Puppies:
                    </label>
                    <textarea
                      value={formData.past_experiences}
                      maxLength="500"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          past_experiences: e.target.value,
                        })
                      }
                      rows="6"
                      className="font-roboto rounded-3xl w-full pl-8 pt-4 focus:outline-blue border border-stone-400"
                    />
                  </div>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      How many hours during each work day will a foster pet be
                      without direct care?
                    </label>
                    <input
                      type="number"
                      maxLength="100"
                      value={formData.work_hours}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          work_hours: parseInt(e.target.value) || 0,
                        })
                      }
                      className="font-roboto rounded-3xl w-full pl-8 focus:outline-blue h-12 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    />
                  </div>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      What is the maximum time you are able to foster?{" "}
                      <span className="text-grey-dark1 text-sm font-roboto font-normal">
                        (Required)
                      </span>
                    </label>
                    <input
                      type="text"
                      maxLength="100"
                      value={formData.max_foster_time}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          max_foster_time: e.target.value,
                        })
                      }
                      className="font-roboto rounded-3xl w-full pl-8 focus:outline-blue h-12"
                    />
                  </div>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      Will you be able to separate foster animals from your own
                      animals?{" "}
                      <span className="text-grey-dark1 text-sm font-roboto font-normal">
                        (Required)
                      </span>
                    </label>
                    <div className="flex items-center gap-4">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          required
                          className="rounded-3xl w-6 h-6 focus:outline-blue mr-2 cursor-pointer"
                          value="yes"
                          id="pet-separation-yes"
                          checked={formData.pet_separation === true}
                          onChange={() =>
                            setFormData({ ...formData, pet_separation: true })
                          }
                        />
                        <label
                          htmlFor="pet-separation-yes"
                          className="cursor-pointer"
                        >
                          Yes
                        </label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="radio"
                          required
                          className="rounded-3xl w-6 h-6 focus:outline-blue mr-2 cursor-pointer"
                          value="no"
                          id="pet-separation-no"
                          checked={formData.pet_separation === false}
                          onChange={() =>
                            setFormData({ ...formData, pet_separation: false })
                          }
                        />
                        <label
                          htmlFor="pet-separation-no"
                          className="cursor-pointer"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      Where will a foster pet stay while you are at work or when
                      you are not home?{" "}
                      <span className="text-grey-dark1 text-sm font-roboto font-normal">
                        (Required)
                      </span>
                    </label>
                    <input
                      type="text"
                      maxLength="100"
                      value={formData.foster_stay}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          foster_stay: e.target.value,
                        })
                      }
                      className="font-roboto rounded-3xl w-full pl-8 focus:outline-blue h-12"
                    />
                  </div>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      Where would a foster pet sleep at night?
                    </label>
                    <input
                      type="text"
                      maxLength="100"
                      value={formData.foster_bed}
                      onChange={(e) =>
                        setFormData({ ...formData, foster_bed: e.target.value })
                      }
                      className="font-roboto rounded-3xl w-full pl-8 focus:outline-blue h-12"
                    />
                  </div>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      Will you be able to transport your foster pet to/from pet
                      adoptions/other events?
                    </label>
                    <div className="flex items-center gap-4 mt-2">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          required
                          className="rounded-3xl w-6 h-6 focus:outline-blue mr-2 cursor-pointer"
                          value="yes"
                          id="transporting-foster-yes"
                          checked={formData.transporting_foster === true}
                          onChange={() =>
                            setFormData({
                              ...formData,
                              transporting_foster: true,
                            })
                          }
                        />
                        <label
                          htmlFor="transporting-foster-yes"
                          className="cursor-pointer"
                        >
                          Yes
                        </label>
                      </div>

                      <div className="flex items-center">
                        <input
                          type="radio"
                          required
                          className="rounded-3xl w-6 h-6 focus:outline-blue mr-2 cursor-pointer"
                          value="no"
                          id="transporting-foster-no"
                          checked={formData.transporting_foster === false}
                          onChange={() =>
                            setFormData({
                              ...formData,
                              transporting_foster: false,
                            })
                          }
                        />
                        <label
                          htmlFor="transporting-foster-no"
                          className="cursor-pointer"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-row w-full justify-start">
                <div className="flex flex-col items-center">
                  <div className="rounded-full w-8 h-8 bg-gray-300 mb-0"></div>
                </div>
                <div className="flex flex-col w-full pb-12 ml-8">
                  <h1 className="text-left !font-normal !text-4xl mb-8">
                    Additional Info
                  </h1>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      Why do you want to foster?
                    </label>
                    <textarea
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          reason_to_foster: e.target.value,
                        })
                      }
                      value={formData.reason_to_foster}
                      rows="6"
                      maxLength="700"
                      className="font-roboto rounded-3xl w-full pl-8 pt-4 focus:outline-blue border border-stone-400"
                    />
                  </div>

                  <div className="w-full my-2.5">
                    <label className="block text-left w-full mb-2">
                      Anything else you would like us to know?
                    </label>
                    <input
                      type="text"
                      maxLength="300"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          additional_info: e.target.value,
                        })
                      }
                      value={formData.additional_info}
                      className="font-roboto rounded-3xl w-full pl-8 focus:outline-blue h-12"
                    />
                  </div>
                </div>
              </div>
            </div>
            <SecondaryButton type="submit" className="!mx-auto !w-72 mb-16">
              Submit
            </SecondaryButton>
          </form>
          <div className="mb-16 mx-auto">
            {errorMsg && <p className="text-error">{errorMsg}</p>}
            {successMsg && <p className="text-success">{successMsg}</p>}
          </div>
        </section>
      ) : (
        <section className="w-full bg-white ">
          <div className="bg-blue py-16 rounded-3xl m-8 2xl:mx-36 px-4">
            <h1 className="text-white !text-3xl !font-normal">
              Please sign up or login to submit a foster interest form!
            </h1>
            <div className="flex flex-col sm:flex-row justify-center items-center gap-8 my-8">
              <PrimaryButton
                className="!w-48"
                onClick={() => openModal("signup")}
              >
                Sign Up
              </PrimaryButton>
              <SecondaryButton
                className="!w-48 border !border-white"
                onClick={() => openModal("login")}
              >
                Login
              </SecondaryButton>
            </div>
          </div>
          {isModalOpen ? (
            loginOrSignup === "login" ? (
              <LoginSignupModal
                loginOrSignup={"login"}
                isOpen={isModalOpen}
                onClose={closeModal}
                onFormSuccess={handleFormSuccess}
              />
            ) : (
              <LoginSignupModal
                loginOrSignup={"signup"}
                isOpen={isModalOpen}
                onClose={closeModal}
                onFormSuccess={handleFormSuccess}
              />
            )
          ) : (
            ""
          )}
        </section>
      )}
    </div>
  );
};

export default FosterInterestPage;
