import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const addToCart = async (data) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    };
    const response = await axios.post(`${API_URL}/users/cart/add/`, data, config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      throw error;
    }
  }
};

export const getCart = async () => {
	try {
		const config = {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    };
		const response = await axios.get(`${API_URL}/users/cart`, config);
		return response.data;
	} catch (error) {
		if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      throw error;
    }
	}
};

export const updateCart = async (data) => {
	try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    };
		const response = await axios.patch(`${API_URL}/users/cart/update/`, data, config);
		return response.data;
	} catch (error) {
		if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      throw error;
    }
	}
}

export const deleteCart = async (data) => {
	try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    };
		const response = await axios.post(`${API_URL}/users/cart/remove/`, data, config);
		return response.data;
	} catch (error) {
		if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      throw error;
    }
	}
}