import { combineReducers, configureStore, createSlice } from "@reduxjs/toolkit";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
// ACCESSING LOCALSTORAGE *******
// const userData = useSelector(
//   (state) => state.userData.userData
// );

const initialUserDataState = {
  userData: {},
};

const userDataReducer = createSlice({
  name: "userData",
  initialState: initialUserDataState,
  reducers: {
    removeUserData(state) {
      state.userData = {};
    },
    updateUserData(state, action) {
      state.userData = action.payload;
    },
  },
});

const rootReducer = combineReducers({
  userData: userDataReducer.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  version: 1,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const userDataActions = userDataReducer.actions;
export default store;

export const persistor = persistStore(store);
