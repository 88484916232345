import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchArticlesPagination = async (page, searchParam) => {
  try {
    const params = {
        page,
        search: searchParam
    }
    const config = {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
    }
    const response = await axios.get(`${API_URL}/articles/`, {params}, config);
    return response.data;
  } catch (error) {
    // Handle or throw error
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Fetch Blogs error:", error.response);
      throw error;
    }
  }
};

export const createArticle = async (formData) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    const response = await axios.post(
      `${API_URL}/articles/create/`,
      formData,
      config
    );
    return response.data;
  } catch (error) {
    console.error("The article could not be created: ", error);
    throw error;
  }
};

export const deleteArticle = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/articles/delete/${id}/`);
    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Delete Article error:", error.response);
      throw error;
    }
  }
};

export const updateArticle = async (articleId, formData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const response = await axios.patch(
      `${API_URL}/articles/update/${articleId}/`,
      formData,
      config
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Article Update Error: ", error.response);
      throw error;
    }
  }
};