import { useEffect, useState } from "react";
import { uploadDonors } from "../../../api/UploadDonors";
import { getDonors } from "../../../api/GetDonors";
import { useNavigate } from "react-router-dom";
import DonorTable from "./DonorTable/DonorTable";
import { lineBreaks } from "../../shared/Utils/LineBreaks";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import classes from "./DonorList.module.css";
import dashboardClasses from "../Dashboard.module.css";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

function DonorList() {
  const [localUserData, setLocalUserData] = useState(null);
  const [donorData, setDonorData] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [feErrorMessage, setFEErrorMessage] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [donationSearchInput, setDonationSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [ifSearch, setIfSearch] = useState(false);
  const [filter, setFilter] = useState("no-filter");
  const navigate = useNavigate();
  const S3_URL =
    "https://sap-app-staging.s3.us-west-2.amazonaws.com/resources/new_donor_list.csv";

  const downloadFile = () => {
    window.location.href = S3_URL;
  };

  const getDonorList = async () => {
    try {
      const response = await getDonors(localUserData.nonprofit);
      if (response.length > 0) {
        setDonorData(response);
      }
    } catch (error) {
      console.error("Error getting Donors: ", error);
    }
  };

  useEffect(() => {
    // Retrieve user data from localStorage
    const userDataString = localStorage.getItem("userData");
    if (userDataString) {
      try {
        const userData = JSON.parse(userDataString);
        setLocalUserData(userData);
      } catch (error) {
        console.error("Failed to parse user data: ", error);
        localStorage.removeItem("userData");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        navigate("/");
      }
    }
  }, [navigate]);

  useEffect(() => {
    if (localUserData) {
      getDonorList();
    }
  }, [localUserData]);

  const uploadDonorList = async (event) => {
    setFEErrorMessage("");
    setErrorMsg("");
    setSuccessMessage("");
    const csvfile = event.target.files[0];
    if (csvfile) {
      if (csvfile.type !== "text/csv") {
        setFEErrorMessage("Please upload a CSV file.");
        return;
      }
      const formData = new FormData();
      formData.append("file", csvfile);
      try {
        if (localUserData) {
          const response = await uploadDonors(
            formData,
            localUserData.nonprofit
          );
          setSuccessMessage(
            "Upload successful. Please refresh the page to view Donors."
          );
        } else {
          navigate("/signup");
        }
      } catch (error) {
        console.error("Error uploading file: ", error.response);
        const errMsg = errorHandler(error);
        setErrorMsg(errMsg);
      }
    }
  };

  function convertToCSV(arr) {
    // Extract column names excluding the 'address' field
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(arr[0]).filter((key) => key !== "address");

    // Join column names to make the CSV header
    let csv = keys.join(columnDelimiter) + lineDelimiter;

    arr.forEach((obj) => {
      csv += keys.map((key) => obj[key]).join(columnDelimiter) + lineDelimiter;
    });

    return csv;
  }

  const downloadDonorsCSV = () => {
    if (donorData && donorData.length > 0) {
      const csvString = convertToCSV(donorData);
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "donors.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const handleDonationSearchChange = (e) => {
    e.preventDefault();
    setDonationSearchInput(e.target.value);

    setIfSearch(true);
    if (e.target.value === "") {
      setIfSearch(false);
    }

    const searchResults = searchDonations();
    setSearchResults(searchResults);
  };

  const searchDonations = () => {
    const filteredData = getFilteredData();
    return filteredData.filter((donation) =>
      donationSearchInput !== ""
        ? donation.email
            .toLowerCase()
            .includes(donationSearchInput.toLowerCase())
        : true
    );
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setDonationSearchInput("");
    setIfSearch(false);
    const filteredData = getFilteredData();
    setSearchResults(filteredData);
  };

  const getFilteredData = () => {
    let filteredData = donorData;

    if (filter === "donations") {
      filteredData = donorData.filter(
        (donation) => donation.source === "donation"
      );
    } else if (filter === "uploaded") {
      filteredData = donorData.filter(
        (donation) => donation.source === "upload"
      );
    } else if (filter === "first_name") {
      filteredData = [...donorData].sort((a, b) =>
        a.first_name.localeCompare(b.first_name)
      );
    } else if (filter === "last_name") {
      filteredData = [...donorData].sort((a, b) =>
        a.last_name.localeCompare(b.last_name)
      );
    } else if (filter === "email") {
      filteredData = [...donorData].sort((a, b) =>
        a.email.localeCompare(b.email)
      );
    }

    return filteredData;
  };

  return (
    <>
      <div
        className={`${dashboardClasses.dashboard_content} ${classes.donor_dashboard_block} w-full ml-14 pt-12`}
      >
        <div className={`${classes.header_section} flex flex-col gap-5 pb-4`}>
          <h2 className="text-orange !text-3xl font-bold text-center">
            Donor List
          </h2>

          {feErrorMessage && <p className="text-error">{feErrorMessage}</p>}

          {successMessage && <p className="text-success">{successMessage}</p>}

          {errorMsg && <p className="text-error">{lineBreaks(errorMsg)}</p>}

          {localUserData &&
            (localUserData.np_access_level === "admin" ||
              localUserData.np_access_level === "editor") && (
              <div className={`flex justify-around items-center gap-4 grow`}>
                <SecondaryButton onClick={downloadFile} className="!text-sm">
                  Download Donor List CSV Template
                </SecondaryButton>
                <SecondaryButton
                  className="cursor-pointer !text-sm"
                  onClick={() =>
                    document.getElementById("donor-list-upload").click()
                  }
                >
                  Upload Donor List
                  <input
                    type="file"
                    onChange={uploadDonorList}
                    className="hidden"
                    id="donor-list-upload"
                  />
                </SecondaryButton>
              </div>
            )}
        </div>

        <div className={`flex items-center relative w-full`}>
        <FontAwesomeIcon
              icon={faMagnifyingGlass}
              size="xs"
              className="absolute left-3"
              color="#666666"
            />
          <div className="flex flex-col md:flex-row items-center w-full">
            <input
              type="text"
              placeholder="Search Donation by Donor Email"
              value={donationSearchInput}
              onChange={handleDonationSearchChange}
              className={`p-2 pl-8 border text-gray-600 border-gray-300 rounded-lg mb-4 w-full h-7 `}
            />
            <select
              className="w-auto ml-2 pl-1 border text-gray-600 border-gray-300 rounded-lg mb-4 h-7 min-w-max"
              value={filter}
              onChange={handleFilterChange}
            >
              <option value="no-filter">No Filter</option>
              <option value="donations">Donations on Sponsor A Pet</option>
              <option value="uploaded">Uploaded Donations from CSV</option>
              <option value="first_name">Sort by First Name</option>
              <option value="last_name">Sort by Last Name</option>
              <option value="email">Sort by Email</option>
            </select>
          </div>
        </div>

        {donorData ? (
          ifSearch ? (
            <>
              <DonorTable
                nonprofitId={localUserData.nonprofit}
                data={searchResults}
              />
              <div
                className={`${classes.donor_dashboard_block} flex flex-col justify-center items-center gap-5 p-4`}
              >
                <SecondaryButton
                  onClick={downloadDonorsCSV}
                  className="!text-sm !w-1/3"
                >
                  Download Donor Data as CSV
                </SecondaryButton>
              </div>
            </>
          ) : (
            <>
              <DonorTable
                nonprofitId={localUserData.nonprofit}
                data={getFilteredData()}
              />
              <div
                className={`${classes.donor_dashboard_block} flex flex-col justify-center items-center gap-5 p-4`}
              >
                <SecondaryButton
                  className="!text-sm !w-1/3"
                  onClick={downloadDonorsCSV}
                >
                  Download Donor Data as CSV
                </SecondaryButton>
              </div>
            </>
          )
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default DonorList;
