import React, { useState, useEffect } from "react";
import SuccessModal from "../../Campaigns/CampaignFeature/SuccessModal/SuccessModal";
import LoginSignupModal from "../../shared/LoginSignupModal/LoginSignupModal";
import classes from "./DonationBox.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  generateNPDonation,
  generateNPSubscription,
} from "../../../api/GenerateDonation";
import {
  NPDonationSuccess,
  NPSubscriptionSuccess,
} from "../../../api/DonationSuccess";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";

const DonationBox = ({ nonprofit }) => {
  const [coverFee, setCoverFee] = useState(true);
  const [prevAmount, setPrevAmount] = useState(0);
  const [customAmount, setCustomAmount] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [, setDonationAmount] = useState(0);
  const [clientSecret, setClientSecret] = useState(null);
  const [donationId, setDonationId] = useState(0);
  const [stripe, setStripe] = useState(null);
  const [, setIsStripeLoading] = useState(true);
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [elements, setElements] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoginSignupModalOpen, setLoginSignupModalOpen] = useState(false);
  const [loginOrSignup, setLoginOrSignup] = useState("");
  const [localUserData, setLocalUserData] = useState(null);
  const [showCustomAmount, setShowCustomAmount] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [donateClicked, setDonateClicked] = useState(false);
  const [ifActiveNPSub, setIfActiveNPSub] = useState(false);

  const userData = useSelector((state) => state.userData.userData);

  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);

  useEffect(() => {
    let np_sub = [];
    if (localUserData && nonprofit) {
      if (
        localUserData.np_subscriptions &&
        localUserData.np_subscriptions.length > 0
      ) {
        np_sub = localUserData.np_subscriptions.filter(
          (sub) => nonprofit.org_name === sub.nonprofit && sub.active === true
        );
        np_sub.length > 0 && setIfActiveNPSub(true);
      }
    }
  }, [localUserData, nonprofit]);

  const handleOptionClick = (amount) => {
    setShowCustomAmount(false);

    if (customAmount) {
      setCoverFee(false);
      setCustomAmount("");
    }

    if (selectedOption === amount) {
      setSelectedOption(null);
      setPrevAmount(0);
      setDonationAmount(0);
    } else {
      setSelectedOption(amount);
      setPrevAmount(amount);
      setDonationAmount(amount);
      setCoverFee(false);
    }
  };

  const handleCoverFeeChange = (e) => {
    setCoverFee(e.target.checked);
    const fee = parseFloat((prevAmount * 0.05).toFixed(2));
    if (e.target.checked) {
      setDonationAmount(Number(prevAmount) + fee);
    } else {
      setDonationAmount(Number(prevAmount));
    }
  };

  const handleInputChange = (e) => {
    setPrevAmount(e.target.value);
    setCustomAmount(e.target.value);
    setDonationAmount(parseFloat(e.target.value));
    if (selectedOption) {
      setSelectedOption(null);
      setCoverFee(false);
    }
  };

  const handleDonationSubmit = async (event) => {
    event.preventDefault();

    let amountToDonate = selectedOption || parseFloat(customAmount);

    if (coverFee) {
      amountToDonate += parseFloat((amountToDonate * 0.03).toFixed(2));
    }

    setDonationAmount(amountToDonate);

    const initializePayment = async (nonprofit) => {
      if (!nonprofit) return;

      const data = {
        user: localUserData?.id || null,
        nonprofit: nonprofit.id,
        amount: amountToDonate,
      };
      try {
        let response;
        if (selectedTab === 0) {
          // ONE-TIME DONATION
          response = await generateNPDonation(data);
        } else if (selectedTab === 1) {
          // MONTHLY DONATION
          response = await generateNPSubscription(data);
        }
        setClientSecret(response.client_secret);
        setDonationId(response.donation_id);
        setDonateClicked(true);
      } catch (error) {
        console.error("Error: ", error);
      }
    };

    initializePayment(nonprofit);
  };

  useEffect(() => {
    if (clientSecret) {
      const stripeInstance = window.Stripe(process.env.REACT_APP_STRIPE_KEY);
      const appearance = {
        /* appearance */
      };
      const options = {
        layout: {
          type: "tabs",
          defaultCollapsed: false,
        },
      };
      const elementsInstance = stripeInstance.elements({
        clientSecret,
        appearance,
      });
      const cardElement = elementsInstance.create("payment", options);
      cardElement.mount("#payment-element");

      cardElement.on("ready", () => {
        setIsStripeLoading(false);
      });

      setStripe(stripeInstance);
      setElements(elementsInstance);
    }
  }, [clientSecret]);

  const handleSubmitPayment = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const paymentElement = elements.getElement("payment");

    if (!paymentElement) {
      setPaymentResponse("Payment Element is not loaded.");
      return;
    }

    setIsStripeLoading(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: "if_required",
      });

      if (error) {
        setPaymentResponse(error.message);
      } else {
        if (paymentIntent && paymentIntent.status === "succeeded") {
          setPaymentResponse({ status: "success" });
          setShowModal(true);
          if (selectedTab === 0) {
            // ONE-TIME DONATION
            await NPDonationSuccess({ donation_id: donationId });
          } else if (selectedTab === 1) {
            // MONTHLY DONATION
            await NPSubscriptionSuccess({ donation_id: donationId });
          }
        } else {
          setPaymentResponse({ error: error });
        }
      }
    } catch (error) {
      setPaymentResponse("An error occurred during payment.");
      console.error(error);
    } finally {
      setIsStripeLoading(false);
    }
  };

  const handleTabChange = (tabIndex) => {
    if (tabIndex === 1 && ifActiveNPSub) {
      alert(
        "You're already subscribed. Go to your profile to modify your subscription."
      );
      return;
    }
    setSelectedTab(tabIndex);
    setShowCustomAmount(false);
    setCoverFee(false);
    setCustomAmount("");
    setSelectedOption(null);
    setPrevAmount(0);
    setDonationAmount(0);
  };

  const handleButtonClick = () => {
    setSelectedOption(null);
    setPrevAmount(0);
    setDonationAmount(0);
    setCoverFee(false);
    setShowCustomAmount(!showCustomAmount);
  };

  const closeModal = () => {
    setShowModal(false);
    window.location.reload();
  };

  const openLoginSignupModal = (type) => {
    setLoginOrSignup(type);
    setLoginSignupModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeLoginSignupModal = () => {
    setLoginSignupModalOpen(false);
    document.body.style.overflow = "";
  };

  const handleFormSuccess = (success) => {
    if (success) {
      window.location.reload();
    }
  };

  return (
    <div
      className={`border items-left align-left rounded-2xl -ml-1 my-8 p-4 shadow-lg sm:min-w-[300px]' ${
        donateClicked ? "max-w-[330px]" : "max-w-[590px]"
      }`}
    >
      <h2 className="flex my-6 text-2xl md:text-3xl text-blue font-bold">
        Support Our Cause
      </h2>

      {!donateClicked && (
        <div className="flex">
          <button
            className={`mr-4 py-2 font-semibold ${
              selectedTab === 0
                ? "text-blue border-b-2 border-blue"
                : "text-gray-500  hover:text-blue"
            }`}
            onClick={() => handleTabChange(0)}
          >
            One-Time Donation
          </button>
          {nonprofit.price_ids && nonprofit.price_ids.length > 0 && (
            <button
              className={`py-2 font-semibold ${
                selectedTab === 1
                  ? "text-blue border-b-2 border-blue"
                  : "text-gray-500 hover:text-blue"
              }`}
              onClick={() => handleTabChange(1)}
            >
              Monthly Donation
            </button>
          )}
        </div>
      )}

      {paymentResponse && paymentResponse.status && showModal ? (
        <SuccessModal
          message="Payment succeeded!"
          onClose={closeModal}
          showModal={showModal}
        />
      ) : null}

      <div>
        {clientSecret ? (
          <form
            id="payment-form"
            className={classes.payment_form}
            onSubmit={handleSubmitPayment}
          >
            <div id="payment-element"></div>
            <SecondaryButton type="submit">Complete Donation</SecondaryButton>
          </form>
        ) : (
          <>
            <div className="flex flex-wrap box:flex-row gap-6 my-4">
              {[10, 25, 50, 100].map((amount) => (
                <button
                  key={amount}
                  className={`${
                    selectedOption === amount
                      ? "!bg-orange !text-white"
                      : "hover:bg-gray-200"
                  } border font-semibold rounded-2xl text-[#0000008F] py-2.5 px-4 box:px-6`}
                  type="button"
                  onClick={() => handleOptionClick(amount)}
                  style={{ border: "1px solid #0000004F" }}
                >
                  ${amount}
                </button>
              ))}
              <button
                className={`${
                  showCustomAmount
                    ? "!bg-orange !text-white"
                    : "hover:bg-gray-200"
                } border font-semibold rounded-2xl text-[#0000008F] py-2.5 px-7`}
                onClick={handleButtonClick}
                style={{ border: "1px solid #0000004F" }}
              >
                Other
              </button>
              {showCustomAmount && (
                <input
                  type="number"
                  id="donation-amount"
                  name="donation-amount"
                  placeholder="Custom donation amount"
                  className="w-full h-7 text-[0.95rem] my-auto rounded-lg border border-gray-400 "
                  value={customAmount}
                  onChange={handleInputChange}
                />
              )}
            </div>
            <form
              id={classes.amount_form}
              className="w-full no-underline"
              onSubmit={handleDonationSubmit}
            >
              <div className="flex flex-row gap-2">
                {selectedTab === 0 && (
                  <>
                    <label htmlFor="coverFee" className="text-sm text-left">
                      Would you like to cover the transaction fee of ($
                      {(prevAmount * 0.03).toFixed(2)})?
                    </label>
                    <div className="-mt-[15.5px]">
                      <input
                        type="checkbox"
                        id="coverFee"
                        checked={coverFee}
                        onChange={handleCoverFeeChange}
                      />
                    </div>
                  </>
                )}
              </div>

              {localUserData && (
                <PrimaryButton
                  id="submit-amount"
                  className={` min-w-[7rem] h-[3rem] ${
                    selectedTab === 0 ? "mt-2" : "mt-2"
                  }`}
                >
                  Donate
                </PrimaryButton>
              )}
            </form>
            {!localUserData && (
              <div className="mt-4">
                <h4 className={`mb-9`}>
                  {isLoginSignupModalOpen ? (
                    loginOrSignup === "login" ? (
                      <LoginSignupModal
                        loginOrSignup={"login"}
                        isOpen={openLoginSignupModal}
                        onClose={closeLoginSignupModal}
                        onFormSuccess={handleFormSuccess}
                      />
                    ) : (
                      <LoginSignupModal
                        loginOrSignup={"signup"}
                        isOpen={openLoginSignupModal}
                        onClose={closeLoginSignupModal}
                        onFormSuccess={handleFormSuccess}
                      />
                    )
                  ) : null}
                  <button
                    className="underline text-orange"
                    onClick={() => openLoginSignupModal("signup")}
                  >
                    Sign Up
                  </button>{" "}
                  or{" "}
                  <button
                    className="underline text-orange"
                    onClick={() => openLoginSignupModal("login")}
                  >
                    login
                  </button>{" "}
                  to donate
                </h4>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DonationBox;
