export const errorHandler = (error) => {
  if (
    error.response &&
    error.response.data &&
    error.response.data.error &&
    error.response.data.error.details
  ) {
    const errorDetails = error.response.data.error.details;
    let combinedErrorMessage = "";
    for (const [key, value] of Object.entries(errorDetails)) {
      if (typeof value === "string") {
        combinedErrorMessage += `${key}: ${value}\n`;
      } else if (typeof value === "object" && Array.isArray(value)) {
        combinedErrorMessage += `${key}: ${value.join(", ")}\n`;
      }
    }
    return combinedErrorMessage;
  } else {
    return "An internal server error occurred. Please contact us for assistance at sponsorapet.org/contact-us";
  }
};

export const eventErrorHandler = (error) => {
  if (error.response && error.response.data) {
    const errorDetails = error.response.data;
    let combinedErrorMessage = "";
    for (const [key, value] of Object.entries(errorDetails)) {
      if (typeof value === "string") {
        combinedErrorMessage += `${key}: ${value}\n`;
      } else if (typeof value === "object" && Array.isArray(value)) {
        combinedErrorMessage += `${key}: ${value.join(", ")}\n`;
      }
    }
    return combinedErrorMessage;
  } else {
    return "An internal server error occurred. Please contact us for assistance at sponsorapet.org/contact-us";
  }
};
