import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import QRCodeModal from "../QRCodeModal/QRCodeModal";
import { urlifyName } from "../../shared/Utils/PageUrl";
import { getNpInfo } from "../../../api/NonprofitInfo";
import CampaignModal from "../CampaignModal/CampaignModal";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import { deleteCampaign, getPaginatedCampaigns } from "../../../api/Campaigns";
import EmailTemplateModal from "../EmailTemplateModal/EmailTemplateModal";
import {
  faPlus,
  faMagnifyingGlass,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import classes from "./DashboardContent.module.css";
import dashboardClasses from "../Dashboard.module.css";
import {
  useMediaQuery,
  Pagination,
  PaginationItem,
  MenuItem,
} from "@mui/material";
import Select from "@mui/material/Select";
import EditCampaignModal from "../EditCampaignModal/EditCampaignModal";
import Spinner from "../../shared/LoadingSpinner/Spinner";
import DashboardBanner from "./DashboardBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UpdatesDropdown from "../../AdminDashboard/UpdatesDropdown";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";
import CampaignCompletePopup from "./CampaignCompletePopup";
import { campaignPopupShown } from "../../../api/CampaignPopupShown";

function isWithinLastWeek(date) {
  let currentDate = new Date();
  let differenceMilliseconds = currentDate - date;
  let millisecondsInDay = 1000 * 60 * 60 * 24;
  let daysDifference = Math.floor(differenceMilliseconds / millisecondsInDay);
  return daysDifference <= 7;
}

function isSameMonthAsToday(date) {
  let currentDate = new Date();
  return (
    date.getFullYear() === currentDate.getFullYear() &&
    date.getMonth() === currentDate.getMonth()
  );
}

// Arrow for drop down buttons
const CustomArrowIcon = () => (
  <FontAwesomeIcon icon={faChevronDown} size="sm" />
);

function DashboardContent({ setTab }) {
  const [localUserData, setLocalUserData] = useState(null);
  const [npData, setNpData] = useState(null);
  const [campaignModal, setCampaignModal] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [updatingCampaignId, setUpdatingCampaignId] = useState(null);
  const [emailModal, setEmailModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [QRModal, setQRModal] = useState(false);
  const [campaignUrl, setCampaignUrl] = useState("");
  const [donationModal, setDonationModal] = useState("");
  const navigate = useNavigate();
  const [donationCalcs, setDonationCalcs] = useState({
    weekly: 0,
    monthly: 0,
    total: 0,
  });
  const [weeklyDonations, setWeeklyDonations] = useState([]);
  const [monthlyDonations, setMonthlyDonations] = useState([]);
  const [ifFilterDonations, setIfFilterDonations] = useState(true);
  const [ifSearchDonations, setIfSearchDonations] = useState(false);
  const [donationFilterBy, setDonationFilterBy] = useState("Filter by");
  const [donations, setDonations] = useState([]);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [campaignSearchInput, setCampaignSearchInput] = useState("");
  const [donationSearchInput, setDonationSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDonations, setCurrentDonations] = useState(donations);
  const [searchResults, setSearchResults] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState({});
  const [expandedDonationIndex, setExpandedDonationIndex] = useState(null);
  const [page, setPage] = useState(1);
  const [ifCampaignExists, setIfCampaignExists] = useState(true);
  const [completedDonations, setCompletedDonations] = useState("");

  const itemsPerPage = 4;

  // Functions to handle mobile screens
  const matchesMax430px = useMediaQuery("(max-width: 430px)");

  const getDonations = (campaigns) => {
    let donationList = [];
    if (!campaigns) {
      return;
    }
    const donationsArr = campaigns.map((campaign) => {
      return campaign.donations_details.map((donation) => ({
        ...donation,
        campaign_name: campaign.animal_name,
      }));
    });
    for (const donations of donationsArr) {
      for (const donation of donations) {
        donationList.push(donation);
        if (isWithinLastWeek(new Date(donation.donation_date))) {
          setWeeklyDonations((prev) => [...prev, donation]);
        }
        if (isSameMonthAsToday(new Date(donation.donation_date))) {
          setMonthlyDonations((prev) => [...prev, donation]);
        }
      }
    }
    setDonations(donationList);
    if (currentDonations.length === 0) {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setCurrentDonations(donationList.slice(startIndex, endIndex));
    }
  };

  useEffect(() => {
    const userDataString = localStorage.getItem("userData");
    if (userDataString) {
      try {
        const userData = JSON.parse(userDataString);
        setLocalUserData(userData);
      } catch (error) {
        console.error("Failed to parse user data: ", error);
        localStorage.removeItem("userData");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        navigate("/");
      }
    }
  }, [navigate]);

  useEffect(() => {
    if (localUserData != null && localUserData.nonprofit) {
      const campaignPopupContent = async () => {
        try {
          const response = await campaignPopupShown(localUserData.nonprofit);
          setCompletedDonations(response);

          return response;
        } catch (error) {
          console.error("Error: ", error);
        }
      };
      campaignPopupContent();
    }
  }, [localUserData]);

  const fetchCampaigns = async (page, search, species, age, nonprofitId) => {
    try {
      const response = await getPaginatedCampaigns(
        page,
        search,
        species,
        age,
        nonprofitId,
        ""
      );

      if (page === 1) {
        setCampaigns(response.results);
      } else {
        setCampaigns([...campaigns, ...response.results]);
        setCampaigns([...campaigns, ...response.results]);
      }

      if (!response.next) {
        setIfCampaignExists(false);
      } else {
        setIfCampaignExists(true);
      }
      return response;
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    if (localUserData && localUserData.nonprofit) {
      const fetchNpInfo = async () => {
        try {
          const response = await getNpInfo(localUserData.nonprofit);
          return response;
        } catch (error) {
          console.error("Error: ", error);
        }
      };

      const fetchDonationDetails = async (tempCampaigns) => {
        let tempDonationCalcs = {
          weekly: 0,
          monthly: 0,
          total: 0,
        };
        tempCampaigns.forEach((campaign) =>
          campaign.donations_details.forEach((donation) => {
            if (isWithinLastWeek(new Date(donation.donation_date)))
              tempDonationCalcs.weekly += Number(donation.amount);
            if (isSameMonthAsToday(new Date(donation.donation_date)))
              tempDonationCalcs.monthly += Number(donation.amount);
            tempDonationCalcs.total += Number(donation.amount);
          })
        );
        return tempDonationCalcs;
      };

      const fetchAll = async () => {
        setIsLoading(true);
        const tempNpData = await fetchNpInfo();
        const tempCampaigns = await fetchCampaigns(
          1,
          "",
          "",
          "",
          localUserData.nonprofit
        );
        const tempDonationCalcs = await fetchDonationDetails(
          tempCampaigns.results
        );
        setNpData(tempNpData);

        setDonationCalcs(tempDonationCalcs);
        getDonations(tempCampaigns.results);
        setIsLoading(false);
      };

      try {
        fetchAll();
      } catch (error) {
        console.error(error);
      }
    }
  }, [localUserData]);

  // Handle open/close modal for Create Animal Campaign
  const handleOpenModal = () => {
    setCampaignModal(true);
    document.body.style.overflow = "hidden";
  };

  const handleCloseModal = () => {
    setCampaignModal(false);
    document.body.style.overflow = "scroll";
  };

  // Handle open/close modal for New Email
  const handleOpenEmailModal = (campaignId) => {
    const campaign = campaigns.find((c) => c.id === campaignId);
    setSelectedCampaign(campaign);
    setEmailModal(true);
    document.body.style.overflow = "hidden";
  };

  const handleCloseEmailModal = () => {
    setEmailModal(false);
    setSelectedCampaign(null);
    document.body.style.overflow = "scroll";
  };

  // Handle open/close modal for Edit/Update Campaign
  const handleOpenModify = (campaignId) => {
    setUpdatingCampaignId(campaignId);
    document.body.style.overflow = "hidden";
  };

  const handleCloseModify = () => {
    setUpdatingCampaignId(null);
    document.body.style.overflow = "scroll";
  };

  const handleButtonClick = (id) => {
    if (confirmDelete[id]) {
      handleDelete(id);
    } else {
      setConfirmDelete((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    }
  };

  if (isLoading) {
    return (
      <div className="flex flex-row ml-auto mt-[25vh] w-full h-full justify-center">
        <Spinner />
      </div>
    );
  }

  // Creates and handles the 'Share' button
  const getCampaignUrlById = (campaignId, animalName) => {
    const { protocol, host } = window.location;
    return `${protocol}//${host}/campaigns/${urlifyName(
      npData.org_name
    )}/${urlifyName(animalName)}/${campaignId}`;
  };

  const handleGenerateQRCode = (campaignId, animalName) => {
    const qrUrl = getCampaignUrlById(campaignId, animalName);
    setQRModal(true);
    setCampaignUrl(qrUrl);
  };

  const handleCloseQRCodeModal = () => {
    setQRModal(false);
    setCampaignUrl("");
  };

  const redirectToCampaignPage = (campaignId, animalName) => {
    const campaignUrl = `/campaigns/${urlifyName(npData.org_name)}/${urlifyName(
      animalName
    )}/${campaignId}`;
    navigate(campaignUrl);
  };

  // Handles delete functionality
  const handleDelete = async (campaignId) => {
    try {
      campaignId = parseInt(campaignId);
      await deleteCampaign(campaignId);
      setCampaigns(campaigns.filter((campaign) => campaign.id !== campaignId));
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const openDonationModal = function (type) {
    setDonationModal(type);
    // donationModalRef.current.showModal();
  };

  const closeDonationModal = function () {
    // donationModalRef.current.close();
    setDonationModal("");
  };

  // Searching Campaigns
  const handleCampaignSearchChange = (e) => {
    setCampaignSearchInput(e.target.value);
    setPage(1);
    const searchCampaigns = fetchCampaigns(
      1,
      e.target.value,
      "",
      "",
      localUserData.nonprofit
    );
  };

  // Handles button to load more campaigns
  const handleLoadMoreCampaigns = () => {
    const response = fetchCampaigns(
      page + 1,
      campaignSearchInput,
      "",
      "",
      localUserData.nonprofit
    );

    setPage(page + 1);
  };

  // Searching Donations
  const handleDonationSearchChange = (e) => {
    setDonationSearchInput(e.target.value);
    setIfSearchDonations(true);
    setIfFilterDonations(false);

    if (e.target.value === "") {
      setIfSearchDonations(false);
      setIfFilterDonations(true);
      setCurrentPage(1);
      setCurrentDonations(donations.slice(0, itemsPerPage));
    } else {
      const searchResults = searchDonations();
      setSearchResults(searchResults);
      setCurrentPage(1);
      setCurrentDonations(searchResults.slice(0, itemsPerPage));
    }
  };

  const searchDonations = () => {
    return donations.filter((donation) =>
      donationSearchInput
        ? donation.donor_email
            .toLowerCase()
            .includes(donationSearchInput.toLowerCase())
        : true
    );
  };

  // Filtering Donations
  const getFilteredDonations = () => {
    if (ifSearchDonations) {
      return searchResults;
    }
    if (ifFilterDonations) {
      switch (donationFilterBy) {
        case "This Week":
          return weeklyDonations;
        case "This Month":
          return monthlyDonations;
        default:
          return donations;
      }
    }
    return donations;
  };

  const handleFilterChange = (e) => {
    const filter = e.target.value;
    setDonationFilterBy(filter);
    setIfFilterDonations(true);
    setIfSearchDonations(false);
    setCurrentPage(1); // Reset to the first page when the filter changes

    let filteredDonations;
    if (filter === "This Week") {
      filteredDonations = weeklyDonations;
    } else if (filter === "This Month") {
      filteredDonations = monthlyDonations;
    } else {
      filteredDonations = donations;
    }

    setCurrentDonations(filteredDonations.slice(0, itemsPerPage));
  };

  // Calculating page numbers and changing pages for Donations
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(donations.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  const skipPages = 2;
  const maxVisiblePages = 6;

  const calculateTotalPages = () => {
    const filteredDonations = getFilteredDonations();
    return Math.ceil(filteredDonations.length / itemsPerPage);
  };

  // Function to render pagination items
  const renderItem = (item) => {
    const totalPages = calculateTotalPages();

    if (item.type === "previous" || item.type === "next") {
      const isPrevious = item.type === "previous";
      let page = isPrevious
        ? Math.max(1, item.page - skipPages + 1)
        : Math.min(totalPages, item.page + skipPages - 1);
      return (
        <PaginationItem {...item} page={page} onChange={handleChangePage} />
      );
    }
    if (matchesMax430px) {
      if (
        item.page > currentPage - Math.floor(maxVisiblePages / 2) &&
        item.page < currentPage + Math.floor(maxVisiblePages / 2)
      ) {
        return <PaginationItem {...item} />;
      }
    } else {
      return <PaginationItem {...item} />;
    }
  };

  // Function to handle page change
  const handleChangePage = (event, value) => {
    setCurrentPage(value);
    const filteredDonations = getFilteredDonations();

    const startIndex = (value - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setCurrentDonations(filteredDonations.slice(startIndex, endIndex));
  };

  const expandDonationInfo = (index) => {
    setExpandedDonationIndex(expandedDonationIndex === index ? null : index);
  };

  return (
    <>
      {localUserData && localUserData.nonprofit && completedDonations != "" ? (
        <CampaignCompletePopup
          completedlist={completedDonations}
          setCampaignModal={setCampaignModal}
        />
      ) : (
        ""
      )}
      <div
        className={`${dashboardClasses.dashboard_content} ${classes.dashboard_content} flex flex-col gap-3.5 pt-12 ml-14`}
      >
        <div className="sm:mb-2 ${classes.dashboard_block} flex flex-col mb-16">
          <h1 className={`${classes.header} text-orange !text-3xl`}>
            Dashboard
          </h1>
        </div>
        {npData && <UpdatesDropdown npData={npData} />}
        <DashboardBanner donations={donations} campaignsCreated={campaigns} />
        <div className="sm:mb-2 !overflow-x-auto rounded-xl bg-orange-100 items-center align-center flex flex-col p-5 gap-1">
          <h2 className="!text-blue !text-2xl font-bold text-left mb-6">
            Your Campaigns
          </h2>
          {npData?.stripe_account?.charges_enabled ? (
            <>
              <div className={`flex flex-col justify-center w-full`}>
                {localUserData &&
                npData.is_approved &&
                (localUserData.np_access_level === "admin" ||
                  localUserData.np_access_level === "editor") ? (
                  <PrimaryButton onClick={handleOpenModal}>
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="font-semibold mr-4"
                    />{" "}
                    New Animal Campaign
                  </PrimaryButton>
                ) : (
                  <p>Only Editors and Admins can create animal campaigns</p>
                )}
              </div>
              <CampaignModal
                nonprofitName={npData.org_name}
                nonprofitId={npData.id}
                nonprofitAddress={npData.address}
                isOpen={campaignModal}
                onClose={handleCloseModal}
                setCampaigns={setCampaigns}
                setQrModalOpen={(boolean) => setQrModalOpen(boolean)}
              />
              <QRCodeModal
                isOpen={qrModalOpen}
                onClose={() => setQrModalOpen(false)}
                campaignUrl={campaignUrl}
              />
            </>
          ) : (
            <>
              <p>
                Complete the Stripe Onboarding in the 'Nonprofit Profile' tab,
                once you receive an email of your Nonprofit being approved, to
                receive donations directly to your bank account.
              </p>
              <p>Watch this guide on how to set up your Stripe Account!</p>
              <div
                className={`${classes.video} !min-w-[500px] sm:mb-2 flex gap-1`}
              >
                <iframe
                  width="150%"
                  height="400"
                  src="https://www.youtube.com/embed/WNJb-iSFC3M?list=PLELFenzodbTGgZvPRlONBqvmTKLmpNaHY"
                  title="How to Connect Your Stripe Account"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
              {npData?.is_approved ? (
                <>
                  <div
                    className={`${classes.campaign_btn_row} flex flex-col justify-center w-full`}
                  >
                    {localUserData &&
                    npData.is_approved &&
                    (localUserData.np_access_level === "admin" ||
                      localUserData.np_access_level === "editor") ? (
                      <PrimaryButton onClick={handleOpenModal}>
                        <FontAwesomeIcon
                          icon={faPlus}
                          className="font-semibold mr-4"
                        />{" "}
                        New Animal Campaign
                      </PrimaryButton>
                    ) : (
                      <p>Only Editors and Admins can create animal campaigns</p>
                    )}
                  </div>
                  <CampaignModal
                    nonprofitName={npData.org_name}
                    nonprofitId={npData.id}
                    nonprofitAddress={npData.address}
                    isOpen={campaignModal}
                    onClose={handleCloseModal}
                    setCampaigns={setCampaigns}
                    setQrModalOpen={(boolean) => setQrModalOpen(boolean)}
                  />
                  <QRCodeModal
                    isOpen={qrModalOpen}
                    onClose={() => setQrModalOpen(false)}
                    campaignUrl={campaignUrl}
                  />
                </>
              ) : (
                ""
              )}
            </>
          )}

          <div className={`flex w-full mt-4 items-center relative`}>
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              size="xs"
              className="absolute left-3"
              color="#666666"
            />
            <input
              type="text"
              placeholder="Search Animal Campaigns"
              value={campaignSearchInput}
              onChange={handleCampaignSearchChange}
              className={`p-2 pl-8 border text-black border-gray-300 rounded-lg w-full h-7 `}
            />
          </div>

          {campaigns && localUserData && npData ? (
            <div className={`flex flex-col w-full mt-4 gap-4`}>
              <div
                className={`flex gap-8 p-4 pb-6 max-w-[50rem] overflow-x-auto`}
              >
                {campaigns.map((item) => {
                  if (item.nonprofit !== localUserData.nonprofit) return null;
                  return (
                    <div
                      className={`pb-4 overflow-y-auto shadow-lg hover:shadow-xl min-w-52 max-w-52 !hover:shadow-lg bg-white w-full !pb-8 h-fit flex flex-col overflow-hidden gap-1 rounded-lg`}
                      key={item.id}
                    >
                      {item.campaign_images && item.campaign_images[0] ? (
                        <div
                          onClick={() =>
                            redirectToCampaignPage(item.id, item.animal_name)
                          }
                          className={`${classes.gridContainer} grid relative z-10 before:content=[""] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:z-10 before:cursor-pointer`}
                        >
                          <img
                            onClick={() =>
                              redirectToCampaignPage(item.id, item.animal_name)
                            }
                            className={`${classes.campaign_image} cursor-pointer w-52 max-h-32 object-cover z-0`}
                            src={item.campaign_images[0].image_url}
                            alt="Campaign"
                          />
                          {/* Darker overlay div */}
                          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 z-20 pointer-events-none"></div>
                          <div
                            className={`${classes.text_overlay} absolute top-3 left-6 text-white text-left flex flex-col justify-center items-center z-50`}
                          >
                            <h3
                              className={`text-orange font-semibold my-2 mx-0 p-0 cursor-pointer pointer-events-auto`}
                              onClick={() =>
                                redirectToCampaignPage(
                                  item.id,
                                  item.animal_name
                                )
                              }
                            >
                              {item.animal_name}
                            </h3>
                          </div>
                        </div>
                      ) : (
                        <img alt="" className="bg-stone-400" />
                      )}

                      {localUserData.np_access_level !== "viewer" ? (
                        <div
                          className={`buttons-portion max-h-44 rounded-lg rounded-t-lg px-4 `}
                        >
                          <progress
                            className={`${classes.progress_bar} w-full h-2.5 my-1`}
                            value={Math.min(
                              item.donation_amount,
                              item.donation_goal
                            )}
                            max={item.donation_goal}
                          />
                          <p
                            className={`!text-sm text-center font-semibold !,t-0 !text-zinc-700 text-left`}
                          >
                            ${item.donation_amount} of ${item.donation_goal}{" "}
                            raised!
                          </p>

                          <SecondaryButton
                            className="!w-full !text-xs my-2"
                            onClick={() => handleOpenModify(item.id)}
                          >
                            Modify Campaign
                          </SecondaryButton>

                          <EditCampaignModal
                            campaignId={item.id}
                            campaignData={item}
                            isOpen={updatingCampaignId === item.id}
                            onClose={handleCloseModify}
                          />

                          <SecondaryButton
                            className="!bg-error hover:!bg-red-800 !w-full !text-xs mb-2"
                            onClick={() => handleButtonClick(item.id)}
                          >
                            {confirmDelete[item.id]
                              ? "Confirm Delete?"
                              : "Delete"}
                          </SecondaryButton>

                          <div
                            className={`${classes.side_by_side_buttons} flex justify-center gap-2`}
                          >
                            <SecondaryButton
                              onClick={() =>
                                handleGenerateQRCode(item.id, item.animal_name)
                              }
                              className="!text-xs !p-2 !w-full"
                            >
                              Share
                            </SecondaryButton>
                            <SecondaryButton
                              onClick={() => handleOpenEmailModal(item.id)}
                              className="!text-xs !p-2 !w-full"
                            >
                              <FontAwesomeIcon
                                icon={faPlus}
                                className="text-xs"
                              />{" "}
                              New Email
                            </SecondaryButton>
                          </div>
                        </div>
                      ) : (
                        <>
                          <progress
                            className={`${classes.progress_bar}`}
                            value={item.donation_amount}
                            max={item.donation_goal}
                          />
                          <SecondaryButton
                            onClick={() =>
                              handleGenerateQRCode(item.id, item.animal_name)
                            }
                          >
                            Share
                          </SecondaryButton>
                        </>
                      )}
                    </div>
                  );
                })}
                {ifCampaignExists && (
                  <SecondaryButton
                    onClick={handleLoadMoreCampaigns}
                    className="!mt-16"
                  >
                    Load More
                  </SecondaryButton>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          <EmailTemplateModal
            isOpen={emailModal}
            onClose={handleCloseEmailModal}
            campaignId={selectedCampaign?.id}
            nonprofitName={npData?.org_name}
            animalName={selectedCampaign?.animal_name}
            nonprofitId={npData?.id}
            customEmailPermission={npData?.custom_emails}
          />
          {QRModal && (
            <QRCodeModal
              isOpen={QRModal}
              onClose={handleCloseQRCodeModal}
              campaignUrl={campaignUrl}
            />
          )}
        </div>
        <div className="sm:mb-2 flex flex-col p-1 gap-1">
          <div className="flex flex-row justify-between w-full">
            <h2 className="!text-blue !text-2xl font-bold text-left mb-6">
              Donations
            </h2>
            <Select
              variant="standard"
              className="!text-gray-600"
              value={donationFilterBy}
              IconComponent={CustomArrowIcon}
              onChange={handleFilterChange}
              name="weekday"
            >
              <MenuItem value="Filter by">Filter by</MenuItem>
              <MenuItem value="This Week">This Week</MenuItem>
              <MenuItem value="This Month">This Month</MenuItem>
            </Select>
          </div>

          <div className="flex">
            <div className="flex-1">
              <div className="flex text-center flex-wrap">
                <div className={`flex items-center relative w-full`}>
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    size="xs"
                    className="absolute left-3"
                    color="#666666"
                  />
                  <input
                    type="text"
                    placeholder="Search Donation by Donor Email"
                    value={donationSearchInput}
                    onChange={handleDonationSearchChange}
                    className={`p-2 pl-8 border text-black border-gray-300 rounded-lg w-full h-7 `}
                  />
                </div>

                {/* Conditionally renders two different tables */}
                {/* if matchesMax430px, renders dropdown rows */}
                {matchesMax430px ? (
                  <div className={dashboardClasses.dashboard_block}>
                    <table
                      className={`${classes.table_styles} !w-full table-fixed !border-separate`}
                    >
                      <thead>
                        <tr>
                          <th className="text-sm font-medium text-neutral-500">
                            Donor Name
                          </th>
                          <th className="text-sm font-medium text-neutral-500">
                            Campaign
                          </th>
                          <th className="text-sm font-medium text-neutral-500">
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Renders when user searches for donations */}
                        {ifSearchDonations ? (
                          currentDonations.length > 0 ? (
                            currentDonations.map((donation, index) => (
                              <>
                                <tr
                                  key={index}
                                  onClick={() => expandDonationInfo(index)}
                                >
                                  <td className="bg-sky-50 mb-14 rounded-l-xl">
                                    <input
                                      className="text-sm font-normal text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                                      type="text"
                                      name="first_name"
                                      value={donation.donor_first_name}
                                      disabled
                                      required
                                    />

                                    {expandedDonationIndex === index && (
                                      <div
                                        className="flex justify-center"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <p className="text-gray-500 !text-xs font-normal flex flex-col m-2 text-left">
                                          Email:{" "}
                                          <span className="text-neutral-600 !text-xs font-bold break-all">
                                            {donation.donor_email}
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  </td>
                                  <td className="bg-sky-50 mb-14">
                                    <input
                                      className="text-sm font-normal text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                                      type="text"
                                      name="campaign_name"
                                      value={donation.campaign_name}
                                      disabled
                                      required
                                    />

                                    {expandedDonationIndex === index && (
                                      <div
                                        className="flex justify-center"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <p className="text-gray-500 !text-xs font-normal flex flex-col m-2 text-left">
                                          Date:{" "}
                                          <span className="text-neutral-600 !text-xs font-bold">
                                            {donation.donation_date}
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  </td>
                                  <td className="bg-sky-50 mb-14">
                                    <p className="!text-sm text-neutral-600 text-center">
                                      ${donation.amount}
                                    </p>
                                  </td>

                                  {expandedDonationIndex === index ? (
                                    <td
                                      className="bg-sky-50 mb-14 rounded-r-xl"
                                      align="center"
                                    >
                                      <FontAwesomeIcon
                                        icon={faChevronUp}
                                        size="lg"
                                        className="absolute left-3"
                                        color="#666666"
                                      />
                                    </td>
                                  ) : (
                                    <td
                                      className="bg-sky-50 mb-14 rounded-r-xl"
                                      align="center"
                                    >
                                      <FontAwesomeIcon
                                        icon={faChevronDown}
                                        size="lg"
                                        className="absolute left-3"
                                        color="#666666"
                                      />
                                    </td>
                                  )}
                                </tr>
                              </>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="4"
                                className="bg-sky-50 rounded-xl text-sm text-neutral-600 text-center"
                              >
                                Sorry, no matching results!
                              </td>
                            </tr>
                          )
                        ) : (
                          ""
                        )}

                        {/* Renders when a user is filtering by week/month or when search is not on */}
                        {ifFilterDonations && !ifSearchDonations ? (
                          currentDonations.length > 0 ? (
                            currentDonations.map((donation, index) => (
                              <>
                                <tr
                                  key={index}
                                  onClick={() => expandDonationInfo(index)}
                                >
                                  <td className="bg-sky-50 rounded-l-xl">
                                    <input
                                      className="text-sm text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                                      type="text"
                                      name="first_name"
                                      value={donation.donor_first_name}
                                      disabled
                                      required
                                    />

                                    {expandedDonationIndex === index && (
                                      <div
                                        className="flex justify-center"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <p className="text-gray-500 !text-xs font-normal flex flex-col m-2 text-center">
                                          Email:{" "}
                                          <span className="text-neutral-600 !text-xs font-bold break-all">
                                            {donation.donor_email}
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  </td>
                                  <td className="bg-sky-50">
                                    <input
                                      className="text-sm text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                                      type="text"
                                      name="campaign_name"
                                      value={donation.campaign_name}
                                      disabled
                                      required
                                    />

                                    {expandedDonationIndex === index && (
                                      <div
                                        className="flex justify-center"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <p className="text-gray-500 !text-xs font-normal flex flex-col m-2 text-center">
                                          Date:{" "}
                                          <span className="text-neutral-600 !text-xs font-bold">
                                            {donation.donation_date}
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  </td>
                                  <td className="bg-sky-50" align="center">
                                    ${donation.amount}
                                  </td>

                                  {expandedDonationIndex === index ? (
                                    <td
                                      className="bg-sky-50 rounded-r-xl"
                                      align="center"
                                    >
                                      <FontAwesomeIcon
                                        icon={faChevronUp}
                                        size="lg"
                                        className="absolute left-3"
                                        color="#666666"
                                      />
                                    </td>
                                  ) : (
                                    <td
                                      className="bg-sky-50 rounded-r-xl"
                                      align="center"
                                    >
                                      <FontAwesomeIcon
                                        icon={faChevronDown}
                                        size="lg"
                                        className="absolute left-3"
                                        color="#666666"
                                      />
                                    </td>
                                  )}
                                </tr>
                              </>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="4"
                                className="bg-sky-50 rounded-xl text-sm text-neutral-600 text-center"
                              >
                                Sorry, no results!
                              </td>
                            </tr>
                          )
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  // Renders a normal table
                  <div className={dashboardClasses.dashboard_block}>
                    <table
                      className={`${classes.table_styles} !w-full table-fixed !border-separate`}
                    >
                      <thead>
                        <tr>
                          <th className="text-sm font-medium text-neutral-500">
                            Donor Name
                          </th>
                          <th className="text-sm font-medium text-neutral-500">
                            Campaign
                          </th>
                          <th className="text-sm font-medium text-neutral-500">
                            Amount
                          </th>
                          <th className="text-sm font-medium text-neutral-500">
                            Date
                          </th>
                          <th className="text-sm font-medium text-neutral-500">
                            Email
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Renders when user searches for donations */}
                        {ifSearchDonations ? (
                          currentDonations.length > 0 ? (
                            currentDonations.map((donation, index) => (
                              <tr key={index}>
                                <td className="bg-sky-50 mb-14 rounded-l-xl">
                                  <input
                                    className="text-sm text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                                    type="text"
                                    name="first_name"
                                    value={donation.donor_first_name}
                                    disabled
                                    required
                                  />
                                </td>
                                <td className="bg-sky-50 mb-14">
                                  <input
                                    className="text-sm text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                                    type="text"
                                    name="campaign_name"
                                    value={donation.campaign_name}
                                    disabled
                                    required
                                  />
                                </td>
                                <td className="bg-sky-50 mb-14">
                                  <p className="!text-sm text-neutral-600 text-center">
                                    ${donation.amount}
                                  </p>
                                </td>
                                <td className="bg-sky-50 mb-14">
                                  <input
                                    className="text-sm text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                                    type="date"
                                    name="date"
                                    value={donation.donation_date}
                                    disabled
                                    required
                                  />
                                </td>
                                <td className="bg-sky-50 mb-14 rounded-r-xl">
                                  <input
                                    className="text-sm text-neutral-800 disabled:bg-sky-50 disabled:border-none text-center"
                                    type="email"
                                    name="email"
                                    value={donation.donor_email}
                                    disabled
                                    required
                                  />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="5"
                                className="bg-sky-50 mb-14 rounded-xl text-sm text-neutral-600 text-center"
                              >
                                Sorry, no matching results!
                              </td>
                            </tr>
                          )
                        ) : (
                          "" // No search results available
                        )}

                        {/* Renders when a user is filtering by week/month or when search is not on */}
                        {
                          ifFilterDonations && !ifSearchDonations ? (
                            currentDonations.length > 0 ? (
                              currentDonations.map((donation, index) => (
                                <tr key={index}>
                                  <td className="bg-sky-50 mb-14 rounded-l-xl">
                                    <input
                                      className="text-sm text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                                      type="text"
                                      name="first_name"
                                      value={donation.donor_first_name}
                                      disabled
                                      required
                                    />
                                  </td>
                                  <td className="bg-sky-50 mb-14">
                                    <input
                                      className="text-sm text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                                      type="text"
                                      name="campaign_name"
                                      value={donation.campaign_name}
                                      disabled
                                      required
                                    />
                                  </td>
                                  <td className="bg-sky-50 mb-14">
                                    <p className="!text-sm text-neutral-600 text-center">
                                      ${donation.amount}
                                    </p>
                                  </td>
                                  <td className="bg-sky-50 mb-14">
                                    <input
                                      className="text-sm text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                                      type="date"
                                      name="date"
                                      value={donation.donation_date}
                                      disabled
                                      required
                                    />
                                  </td>
                                  <td className="bg-sky-50 mb-14 rounded-r-xl">
                                    <input
                                      className="text-sm text-neutral-800 disabled:bg-sky-50 disabled:border-none text-center"
                                      type="email"
                                      name="email"
                                      value={donation.donor_email}
                                      disabled
                                      required
                                    />
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan="5"
                                  className="bg-sky-50 rounded-xl text-sm text-neutral-600 text-center"
                                >
                                  Sorry, no results!
                                </td>
                              </tr>
                            )
                          ) : (
                            ""
                          ) // No filtered results available
                        }
                      </tbody>
                    </table>
                  </div>
                )}

                <div
                  className={`${classes.donation_page_button} flex flex-row justify-between w-full p-5`}
                >
                  <SecondaryButton
                    className="!text-sm"
                    onClick={() => setTab("Donations")}
                  >
                    See all donations
                  </SecondaryButton>
                  <nav className="overflow-x-auto max-w-full">
                    <Pagination
                      sx={{
                        ".MuiPaginationItem-root": {
                          backgroundColor: "var(--light-grey)",
                          borderRadius: "0",
                        },
                        ".Mui-selected": {
                          backgroundColor: "var(--default-orange) !important",
                          borderRadius: "0",
                        },
                      }}
                      count={calculateTotalPages(
                        donationFilterBy,
                        weeklyDonations,
                        monthlyDonations,
                        donations,
                        itemsPerPage,
                        searchResults
                      )}
                      page={currentPage}
                      className="flex list-none justify-center"
                      color="primary"
                      renderItem={renderItem}
                      onChange={handleChangePage}
                    />
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardContent;
