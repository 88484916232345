import React from "react";
import classes from "./Affiliate.module.css";
import dogImage2 from "../../assets/affiliate-dog-2.png";
import sunglassesDog from "../../assets/cute-smiley-dog-wearing-sunglasses-removebg-preview.png";
import image1 from "../../assets/roberto-nickson-_JGVVEfbTVQ-unsplash.png";
import image2 from "../../assets/michael-tomaszewski-e1zCiCr6SHQ-unsplash.png";
import image3 from "../../assets/manuel-meza-KvKop_a_EXw-unsplash.png";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../shared/Buttons/PrimaryButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDatabase,
  faHeart,
  faUsers,
  faRocket,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

function AffiliateLanding() {
  const navigate = useNavigate();

  const handleSignUpButton = (e) => {
    e.preventDefault();
    navigate("/affiliates/signup");
  };

  return (
    <>
      <div className="main-div !mb-0">
        <section className="bg-blue flex flex-col sm:flex-row items-center">
          <div className="h-full mx-8 max-[640px]:mr-20 sm:mr-0 my-10 sm:my-6 md:ml-4 lg:mx-14 xl:mx-24 space-y-4 max-w-[40rem]">
            <h1
              className={`text-white text-start !text-[2.25rem] sm:!text-[1.8rem] md:!text-[2.4rem] lg:!text-[3.2rem] xl:!text-[3.75rem] !leading-tight`}
            >
              Become a Sponsor a Pet
              <span className={`text-orange-400`}>
                &nbsp;Affiliate Partner!
              </span>
            </h1>
            <div className={`lg:mr-auto xl:mr-0 text-start`}>
              <p className={`text-white !text-sm md:!text-lg`}>
                <span className="font-bold">
                  Yes, you too can learn to earn passive income online
                </span>{" "}
                by simply sharing your favorite animal non-profits and links to
                help animals in need!
              </p>
            </div>
            <div className="flex items-start">
              <PrimaryButton
                onClick={handleSignUpButton}
                className="lg:mr-auto xl:mr-0 sm:!text-lg"
              >
                Sign up now
              </PrimaryButton>
            </div>
          </div>
          <img
            className="h-[90vw] max-h-[25rem] sm:h-[45vw] sm:max-h-[45rem] ml-auto"
            src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/Affiliate-page/header-pic.png?v=2"
          />
        </section>
        <section
          className={`${classes.why_partner_section} relative flex flex-col justify-center align-center z-0`}
        >
          <div className={`${classes.bar_header} relative z-10`}>
            <div className={`${classes.subheader} text-center z-20`}>
              <p className={`${classes.why_button} md:pr-0 pr-6`}>
                Why Partner with Sponsor A Pet?
              </p>
            </div>
            <img
              className={`hidden md:block absolute z-30 max-[1200px]:!bottom-[58%] max-[1400px]:!bottom-[65%] max-[1400px]:!left-[6%] max-[666px]:!bottom-[80%] max-[666px]:!left-[10%]`}
              src={dogImage2}
              alt={dogImage2}
            ></img>
          </div>
          <div className={`${classes.reasons_section}`}>
            <div className={`${classes.reasons}`}>
              <FontAwesomeIcon
                icon={faDatabase}
                size="5x"
                className="text-blue"
              />
              <div className={`${classes.texts}`}>
                <h2 className={`${classes.subtitle} -mb-8`}>
                  Earn While You Help
                </h2>
                <p className={`${classes.description}`}>
                  Turn your passion for animals into a source of income. Our
                  affiliate program offers generous commission rates that reward
                  your efforts.
                </p>
              </div>
            </div>
            <div className={`${classes.reasons}`}>
              <FontAwesomeIcon icon={faHeart} size="5x" className="text-blue" />
              <div className={`${classes.texts}`}>
                <h2 className={`${classes.subtitle} -mb-8`}>Be a Lifesaver</h2>
                <p className={`${classes.description}`}>
                  As an affiliate, each donation you generate helps provide
                  food, medical care, and shelter to rescued animals. By
                  partnering with us you become a vital part of a mission that
                  saves lives
                </p>
              </div>
            </div>
            <div className={`${classes.reasons}`}>
              <FontAwesomeIcon icon={faUsers} size="5x" className="text-blue" />
              <div className={`${classes.texts}`}>
                <h2 className={`${classes.subtitle}`}>
                  Join a Supportive Community
                </h2>
                <p className={`${classes.description}`}>
                  Become part of a community of animal lovers. We're here to
                  support you every step of the way, providing the resources and
                  encouragement you need to succeed
                </p>
              </div>
            </div>
            <div className={`${classes.reasons}`}>
              <FontAwesomeIcon
                icon={faRocket}
                size="5x"
                className="text-blue"
              />
              <div className={`${classes.texts}`}>
                <h2 className={`${classes.subtitle} -mb-8`}>
                  Maximize Your Reach
                </h2>
                <p className={`${classes.description}`}>
                  Leverage the power of our platform to expand your reach and
                  increase your impact. With our tools and support, you can
                  effectively promote the cause and maximize your earnings.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section
          className={`${classes.banner} relative z-0 flex flex-col justify-center align-center gap-9`}
        >
          <h2 className={`${classes.banner_title} relative z-20`}>
            Promote Non-Profits you love. Earn money while saving animals.
          </h2>
          <div className="z-50 flex justify-center">
            <PrimaryButton
              className="!text-2xl !px-10 !py-4 !rounded-full !text-blue !bg-white hover:!bg-transparent hover:!text-white border duration-300"
              onClick={handleSignUpButton}
            >
              Get Started
            </PrimaryButton>
          </div>
          <img
            src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/Affiliate-page/paw-print.png"
            class="absolute h-full w-auto z-0 left-1/2 -translate-x-1/2"
          />
        </section>
        <section
          className={`${classes.steps_section} relative z-0 flex flex-col justify-center align-center`}
        >
          <div className={`${classes.header}`}>
            <p>Step by Step</p>
            <h3>How It Works in a Nutshell</h3>
          </div>
          <div className={`${classes.step_bottom} flex flex-row gap-9`}>
            <img
              className="hidden md:block"
              src={sunglassesDog}
              alt={sunglassesDog}
            ></img>
            <div className={`${classes.count_steps} flex flex-col`}>
              <div className={`${classes.horizontal_count}`}>
                <img
                  src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/Affiliate-page/horizontal-count.png"
                  className="!w-[44vw]"
                />
              </div>
              <div className={`${classes.vertical_count} h-full`}>
                <div>
                  <img
                    src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/Affiliate-page/vertical-count.png"
                    className="!mt-0"
                  />
                </div>
              </div>
              <div className={`${classes.steps} flex flex-row text-left`}>
                <div className={`${classes.step}`}>
                  <h4>Register</h4>
                  <p>
                    Sign up by making an account with Sponsor a Pet and creating
                    your profile.
                  </p>
                </div>
                <div className={`${classes.step}`}>
                  <h4>Choose your Program</h4>
                  <p>
                    Choose between receiving a 5% commission or donating to your
                    favorite non-profit and receiving a higher commission rate!
                  </p>
                </div>
                <div className={`${classes.step}`}>
                  <h4>Promote</h4>
                  <p>
                    We show you easy and effective ways that will drive targeted
                    traffic so you can start earning on day one!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className={`${classes.banner} relative z-0 flex flex-col justify-center align-center gap-9`}
        >
          <h2 className={`${classes.banner_title} relative z-20`}>
            You provide the audience, We provide the community.
          </h2>
          <div className="z-50 flex justify-center">
            <PrimaryButton
              className="!text-2xl !px-10 !py-4 !rounded-full !text-blue !bg-white hover:!bg-transparent hover:!text-white border duration-300"
              onClick={handleSignUpButton}
            >
              Get Started
            </PrimaryButton>
          </div>
          <img
            src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/Affiliate-page/paw-print.png"
            class="absolute h-full w-auto z-0 left-1/2 -translate-x-1/2"
          />
        </section>
        <section
          className={`${classes.community_section_new} flex flex-col justify-center align-center`}
        >
          <h2 className={`${classes.community_text} text-3xl my-16`}>
            Join a Community of Animal lovers!
          </h2>
          <div
            className={`${classes.animal_lovers} flex flex-row justify-around align-center`}
          >
            <div className={`${classes.animal_block}`}>
              <img src={image1} alt={image1}></img>
              <div
                className={`${classes.user} flex flex-row justify-around align-center`}
              >
                <p>Sarah Khan</p>
              </div>
            </div>
            <div className={`${classes.animal_block}`}>
              <img src={image2} alt={image2}></img>
              <div
                className={`${classes.user} flex flex-row justify-around align-center`}
              >
                <p>Emily Park</p>
              </div>
            </div>
            <div className={`${classes.animal_block}`}>
              <img src={image3} alt={image3}></img>
              <div
                className={`${classes.user} flex flex-row justify-around align-center`}
              >
                <p>Kathrine White</p>
              </div>
            </div>
          </div>
        </section>
        <section
          className={`${classes.banner_2} relative z-0 flex justify-around`}
        >
          <h2 className={`text-white`}>Hey there, have you heard about us?</h2>
          <img
            className={`hidden md:block absolute z-10`}
            src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/golden-dog.png"
            alt="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/golden-dog.png"
          ></img>
          <img
            className={`sm:block sm:h-8 md:hidden lg:hidden z-10`}
            src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/cat.png"
            alt="Tan cat looking cute"
          ></img>
        </section>
        <section className={`${classes.review_section} relative z-20`}>
          <div className={`${classes.header}`}>
            <p>Member Feedback</p>
            <h3>What People Say</h3>
          </div>
          <div className={`${classes.carousel}`}>
            <div
              className={`${classes.review} flex flex-row justify-center align-center`}
            >
              <div className={`${classes.img_section}`}>
                <img
                  src={
                    "https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/Affiliate-page/man-with-dog.png"
                  }
                  alt=""
                ></img>
              </div>
              <div className={`${classes.text_section} flex flex-col`}>
                <div className="flex gap-1">
                  <FontAwesomeIcon
                    icon={faStar}
                    size="lg"
                    className="text-blue"
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    size="lg"
                    className="text-blue"
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    size="lg"
                    className="text-blue"
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    size="lg"
                    className="text-blue"
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    size="lg"
                    className="text-blue"
                  />
                </div>
                <p>
                  "The Sponsor a Pet Affiliate Program lets me promote my
                  passion for animals while earning money. It's a win-win
                  situation for the animals and myself involved."
                </p>
                <div className={`${classes.reviewer_name}`}>
                  <p>
                    Paul Bartlett
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${classes.metrics} flex justify-center align-center`}
          >
            <div className={`${classes.metric}`}>
              <p>
                <span>360+</span> <br />
                Enrolled Members
              </p>
            </div>
            <div className={`${classes.metric}`}>
              <p>
                <span>1200+</span> <br />
                Animals Helped
              </p>
            </div>
            <div className={`${classes.metric}`}>
              <p>
                <span>$5.5M+</span> <br />
                Earnings in total
              </p>
            </div>
            <div className={`${classes.metric}`}>
              <p>
                <span>100%</span> <br />
                Satisfaction rate
              </p>
            </div>
          </div>
        </section>
        <section className={`${classes.contact_section} relative z-0`}>
          <img
            className={`relative z-10`}
            src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/contact-us-affiliate.jpg"
            alt="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/contact-us-affiliate.jpg"
          ></img>
          <div className={`${classes.contact_us} absolute z-30 flex flex-col`}>
            <p>Contact Us</p>
            <h2 className={`text-white `}>Ready to Partner?</h2>
            <PrimaryButton
              className="!px-10 !py-6 duration-300"
              onClick={handleSignUpButton}
            >
              JOIN THE SPONSOR A PET AFFILIATE PROGRAM
            </PrimaryButton>
          </div>
        </section>
      </div>
    </>
  );
}

export default AffiliateLanding;
