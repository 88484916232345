import React, { useState, useEffect } from "react";
import { fetchBlogs } from "../../../api/FetchBlogs";
import BlogCard from "./BlogCard/BlogCard";
import { subscribeMarketingNewsletter } from "../../../api/SubscribeNewsletter";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { lineBreaks } from "../../shared/Utils/LineBreaks";
import classes from "./BlogSearch.module.css";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";

function BlogSearch() {
  const [blogPosts, setBlogPosts] = useState([]);
  const [activeCategory, setActiveCategory] = useState("blogs");
  const [email, setEmail] = useState("");
  const [error, setError] = useState({ error: "", success: "" });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    subscribeToNewsletter(email);
  };

  const subscribeToNewsletter = async (email) => {
    const payload = {
      email: email,
      active: false,
    };
    try {
      const response = await subscribeMarketingNewsletter(payload);
      setError({ error: "", success: "Successfully subscribed" });
      setEmail("");
      setTimeout(() => {
        setError({ error: "", success: "" });
      }, 2000);
    } catch (error) {
      let errorHandle = errorHandler(error);
      if (errorHandle.includes("email:")) {
        errorHandle = errorHandle.substring(7, errorHandle.length - 1);
      }

      const errorLine = lineBreaks(errorHandle);
      setError({ error: errorLine, success: "" });
    }
  };

  useEffect(() => {
    const getBlogPosts = async () => {
      try {
        const response = await fetchBlogs();
        const sortedBlogs = response.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setBlogPosts(sortedBlogs);
      } catch (error) {
        console.error(error);
      }
    };
    getBlogPosts();
  }, []);

  const filteredBlogs = blogPosts.filter(
    (blog) => blog.category === activeCategory
  );

  return (
    <div className={`${classes.pet_blog_page} main-div`}>
      <h1 className="text-orange mb-4">The Daily Pet</h1>
      <header className={classes.blog_header}>
        <h5 className="!text-black font-bold my-4 ml-16">
          Subscribe to get the best pet stories in your inbox:
        </h5>

        {/* Subscription form etc. */}
      </header>
      <form
        className={`${classes.newsletter_form} w-full mb-8`}
        onSubmit={handleSubmit}
      >
        <input
          className={classes.newsletter_input}
          type="email"
          id="newsletter-email"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange}
        />
        <SecondaryButton type="submit">Subscribe</SecondaryButton>
      </form>
      {error.success && (
        <p className="text-green-500 -mt-5 mb-4">{error.success}</p>
      )}
      {error.error && (
        <p className="text-[var(--default-red)] -mt-5 mb-4">{error.error}</p>
      )}
      <div className={classes.category_tabs}>
        <div
          className={`${classes.tab} ${
            activeCategory === "blogs" ? `${classes.active}` : ""
          }`}
          onClick={() => setActiveCategory("blogs")}
        >
          The latest
        </div>
        <div
          className={`${classes.tab} ${
            activeCategory === "corporate" ? `${classes.active}` : ""
          }`}
          onClick={() => setActiveCategory("corporate")}
        >
          Corporate Sponsors
        </div>
        <div
          className={`${classes.tab} ${
            activeCategory === "success" ? `${classes.active}` : ""
          }`}
          onClick={() => setActiveCategory("success")}
        >
          Success Stories
        </div>
      </div>
      <div className={classes.category_content}>
        {filteredBlogs.map((blog, index) => (
          <BlogCard key={blog.id} {...blog} />
        ))}
      </div>
    </div>
  );
}

export default BlogSearch;
