import React, { useState, useEffect } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../../api/UserContext";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import classes from "./Header.module.css";
import { useDispatch, useSelector } from "react-redux";
import { userDataActions } from "../../../store";

const logo =
  "https://sap-app-prod.s3.us-west-2.amazonaws.com/homepage_images/sap_logo_new.png";

const HEADER_DROPDOWNS = {
  user: "user",
  hamburger: "hamburger",
  community: "community",
  services: "services",
  company: "company",
};

function Header({ sticky }) {
  const [visibleDropdown, setVisibleDropdown] = useState("");
  const [localUserData, setLocalUserData] = useState(null);
  const { updateUser } = useUser();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const location = useLocation();
  const [back, setBack] = useState("/");
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const [openBanner, setOpenBanner] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const hideDropdown = () => setVisibleDropdown("");
  const toggleDropdown = (dropdown) => {
    if (visibleDropdown) hideDropdown();
    else setVisibleDropdown(dropdown);
  };
  const toggleDropdownMobile = (dropdown) => {
    if (visibleDropdown === dropdown)
      setVisibleDropdown(HEADER_DROPDOWNS.hamburger);
    else setVisibleDropdown(dropdown);
  };

  useEffect(() => {
    // Retrieve user data and access token from localStorage
    const userDataString = localStorage.getItem("userData");
    const accessToken = localStorage.getItem("accessToken");

    if (userDataString && accessToken) {
      try {
        const userData = JSON.parse(userDataString);

        // Check if the access token is expired
        if (isTokenExpired(accessToken)) {
          // Clear expired data and log the user out
          localStorage.removeItem("userData");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("accessToken");
          setLocalUserData(null);
          navigate("/login");
        } else {
          setLocalUserData(userData);
        }
      } catch (error) {
        console.error("Failed to parse user data: ", error);

        // Clear corrupted data and redirect
        localStorage.removeItem("userData");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        setLocalUserData(null);
        navigate("/");
      }
    }
  }, [userData]);

  function isTokenExpired(token) {
    try {
      const payload = JSON.parse(atob(token.split(".")[1])); // Decode JWT payload
      return payload.exp * 1000 < Date.now(); // Check if token is expired
    } catch (error) {
      console.error("Failed to decode token: ", error);
      return true; // Consider token expired if it cannot be decoded
    }
  }

  useEffect(() => {
    // Function to check if the click is outside the dropdown
    const handleClickOutside = (event) => {
      const headerNavComponents = [
        classes.nav_dropdown,
        classes.header_nav_dropdown,
        classes.header_icons,
      ];
      if (
        visibleDropdown &&
        !headerNavComponents.some((className) =>
          event.target.closest(`.${className}`)
        )
      ) {
        hideDropdown();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [visibleDropdown]);

  useEffect(() => {
    if (!location || !location.pathname) return;
    if (!(location.pathname === "/signup" || location.pathname === "/login"))
      setBack(location.pathname);
  }, [navigate, location]);

  const logout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");

    if (Object.keys(userData).length !== 0) {
      dispatch(userDataActions.removeUserData());
    }

    updateUser && updateUser(null);
    setLocalUserData(null);
    navigate("/");
  };

  useEffect(() => {
    if (window.location.pathname === "/petstar") {
      if (searchParams.get("affiliate_id")) {
        setOpenBanner(false);
      } else {
        setOpenBanner(true);
      }
    } else {
      setOpenBanner(false);
    }
  }, [window.location.pathname]);

  return (
    <>
      <div
        className={
          sticky ? `${classes.header} ${classes.header_sticky}` : classes.header
        }
      >
        <div className={classes.header_left}>
          <a href="/" className={classes.header_logo_link}>
            <img
              src={logo}
              className={
                width >= 1200
                  ? `${classes.header_logo} p-0.6`
                  : `${classes.header_logo_mobile}`
              }
              alt="Sponsor a Pet"
            />
          </a>
        </div>
        {width >= 1200 ? (
          <div className="flex items-center justify-start grow">
            <nav className="flex items-center">
              <Link
                to="/campaigns"
                className="py-2.5 px-3.5 text-zinc-800 !text-base inline-block font-roboto"
              >
                Donate
              </Link>
              <Link
                to="/petstar"
                className="py-2.5 px-3.5 text-zinc-800 !text-base inline-block font-roboto"
              >
                PetStar
              </Link>
              <Link
                to="/non-profit"
                className="py-2.5 px-3.5 text-zinc-800 !text-base inline-block font-roboto"
              >
                Nonprofits
              </Link>

              <div className={classes.nav_dropdown}>
                <p
                  className="flex flex-row gap-2 justify-center items-center py-2.5 px-3.5 text-zinc-800 !text-base inline-block"
                  onClick={() => {
                    toggleDropdown(HEADER_DROPDOWNS.community);
                  }}
                >
                  Community
                  <FontAwesomeIcon
                    icon={
                      visibleDropdown === HEADER_DROPDOWNS.community
                        ? faAngleUp
                        : faAngleDown
                    }
                  />
                </p>
                {/* Dropdown content here */}
                {visibleDropdown === HEADER_DROPDOWNS.community && (
                  <div className={classes.header_nav_dropdown}>
                    <Link
                      to="/foster-interest"
                      className="text-black py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                      onClick={hideDropdown}
                    >
                      Foster Interest
                    </Link>
                    <Link
                      to="/partners"
                      className="text-black py-3 pl-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                      onClick={hideDropdown}
                    >
                      Corporate Partners
                    </Link>
                    <Link
                      to="/events"
                      className="text-black py-3 px-4 text-sm block text-left border-none font-roboto"
                      onClick={hideDropdown}
                    >
                      Events
                    </Link>
                  </div>
                )}
              </div>

              {/* TODO: Unhide these nav items. Bottom is hidden until these pages are made */}
              <div className={classes.nav_dropdown}>
                <p
                  className="flex flex-row gap-2 justify-center items-center py-2.5 px-3.5 text-zinc-800 !text-base inline-block font-roboto"
                  onClick={() => {
                    toggleDropdown(HEADER_DROPDOWNS.services);
                  }}
                >
                  Services
                  <FontAwesomeIcon
                    icon={
                      visibleDropdown === HEADER_DROPDOWNS.services
                        ? faAngleUp
                        : faAngleDown
                    }
                  />
                </p>
                {visibleDropdown === HEADER_DROPDOWNS.services && (
                  <div className={classes.header_nav_dropdown}>
                    {/* <Link
                      to="/resources"
                      className="text-black py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                      onClick={hideDropdown}
                    >
                      Resources
                    </Link> */}
                    <Link
                      to="/consulting-services"
                      className="text-black py-3 px-4 text-sm block text-left font-roboto"
                      onClick={hideDropdown}
                    >
                      Consulting
                    </Link>
                    {/* <Link
                      to="/foster"
                      className="text-black py-3 px-4 text-sm block text-left border-none font-roboto"
                      onClick={hideDropdown}
                    >
                      Foster
                    </Link> */}
                  </div>
                )}
              </div>

              {/* TODO: 'Our team' needs to be unhidden after page is made */}
              <div className={classes.nav_dropdown}>
                <p
                  className="flex flex-row gap-2 justify-center items-center py-2.5 px-3.5 text-zinc-800 !text-base inline-block"
                  onClick={() => {
                    toggleDropdown(HEADER_DROPDOWNS.company);
                  }}
                >
                  Company
                  <FontAwesomeIcon
                    icon={
                      visibleDropdown === HEADER_DROPDOWNS.company
                        ? faAngleUp
                        : faAngleDown
                    }
                  />
                </p>
                {/* Dropdown content here */}
                {visibleDropdown === HEADER_DROPDOWNS.company && (
                  <div className={classes.header_nav_dropdown}>
                    <Link
                      to="/about-us"
                      className="text-black py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                      onClick={hideDropdown}
                    >
                      About Us
                    </Link>
                    <Link
                      to="/faq"
                      className="text-black py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                      onClick={hideDropdown}
                    >
                      FAQ
                    </Link>
                    <Link
                      to="/our-team"
                      className="text-black py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                      onClick={hideDropdown}
                    >
                      Our Team
                    </Link>
                    <Link
                      to="/blogs"
                      className="text-black py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                      onClick={hideDropdown}
                    >
                      Blogs
                    </Link>
                    <Link
                      to="/shop"
                      className="text-black py-3 px-4 text-sm block text-left border-none font-roboto"
                    >
                      Shop
                    </Link>
                  </div>
                )}
              </div>
            </nav>

            {localUserData ? (
              <div className={classes.header_auth_buttons_wrapper}>
                {localUserData.user_type === "internal_admin" ? (
                  <Link
                    to="/internal/dashboard"
                    className="!bg-orange hover:!bg-orange-dark3 hover:!shadow-buttonHover active:!shadow-buttonClick !flex !justify-center items-center !text-base !font-semibold !px-4 !py-2 !text-white !rounded-3xl !shadow-button !whitespace-nowrap !w-min"
                  >
                    Profile
                  </Link>
                ) : (
                  <Link
                    to="/profile"
                    className="!bg-orange hover:!bg-orange-dark3 hover:!shadow-buttonHover active:!shadow-buttonClick !flex !justify-center items-center !text-base !font-semibold !px-4 !py-2 !text-white !rounded-3xl !shadow-button !whitespace-nowrap !w-min"
                  >
                    Profile
                  </Link>
                )}
                <Link
                  to="/"
                  onClick={logout}
                  className="!bg-blue hover:!bg-blue-dark3 hover:!shadow-buttonHover active:!shadow-buttonClick !flex !justify-center !items-center !text-base !font-semibold !px-4 !py-2 !text-white !rounded-3xl !shadow-button !whitespace-nowrap !w-min"
                >
                  Logout
                </Link>
              </div>
            ) : location ? (
              <div className="flex flex-row gap-4 ml-auto">
                <Link
                  to="/signup"
                  onClick={hideDropdown}
                  state={{ back: back }}
                  className="bg-orange hover:bg-orange-dark1 hover:shadow-buttonHover active:shadow-buttonClick justify-center items-center text-base !font-semibold px-4 py-2 text-white rounded-3xl shadow-button whitespace-nowrap w-min"
                >
                  Signup
                </Link>
                <Link
                  to="/login"
                  onClick={hideDropdown}
                  state={{ back: back }}
                  className="bg-blue hover:bg-blue-dark1 hover:shadow-buttonHover active:shadow-buttonClick justify-center items-center text-base !font-semibold px-4 py-2 text-white rounded-3xl shadow-button whitespace-nowrap w-min"
                >
                  Login
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="flex items-center justify-start grow">
            {/* Mobile nav */}
            <div className={classes.header_icons}>
              <FontAwesomeIcon
                icon={faBars}
                className={classes.icon}
                onClick={() => {
                  toggleDropdown(HEADER_DROPDOWNS.hamburger);
                }}
              />
              {/* Dropdown content here */}
              {visibleDropdown !== "" && (
                <div
                  className={`${classes.header_nav_dropdown} ${
                    classes.header_nav_dropdown_mobile
                  } !overflow-scroll md:!overflow-hidden ${
                    visibleDropdown && "!pb-16"
                  } !bg-orange`}
                >
                  {localUserData ? (
                    <>
                      <Link
                        to="#"
                        onClick={logout}
                        className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                      >
                        Logout
                      </Link>
                      <Link
                        to="/profile"
                        className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                        onClick={hideDropdown}
                      >
                        Profile
                      </Link>
                    </>
                  ) : location ? (
                    <>
                      <Link
                        to="/signup"
                        className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                        onClick={hideDropdown}
                        state={{ back: back }}
                      >
                        Signup
                      </Link>
                      <Link
                        to="/login"
                        className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                        onClick={hideDropdown}
                        state={{ back: back }}
                      >
                        Login
                      </Link>
                    </>
                  ) : (
                    ""
                  )}
                  <Link
                    to="/campaigns"
                    className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                    onClick={hideDropdown}
                  >
                    Donate
                  </Link>
                  <Link
                    to="/petstar"
                    className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                    onClick={hideDropdown}
                  >
                    PetStar
                  </Link>
                  <Link
                    to="/non-profit"
                    className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                    onClick={hideDropdown}
                  >
                    Nonprofits
                  </Link>

                  {/* Dropdown for Community */}
                  <div
                    className={classes.hamburger_link}
                    onClick={() => {
                      toggleDropdownMobile(HEADER_DROPDOWNS.community);
                    }}
                  >
                    <p className="!text-sm" onClick={hideDropdown}>
                      Community
                    </p>
                    <FontAwesomeIcon
                      className="mt-1.5"
                      icon={
                        visibleDropdown === HEADER_DROPDOWNS.community
                          ? faAngleUp
                          : faAngleDown
                      }
                    />
                  </div>
                  {visibleDropdown === HEADER_DROPDOWNS.community ? (
                    <div className="pl-[32px]">
                      <Link
                        to="/foster-interest"
                        className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                        onClick={hideDropdown}
                      >
                        Foster Interest
                      </Link>
                      <Link
                        to="/partners"
                        className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                        onClick={hideDropdown}
                      >
                        Corporate Partners
                      </Link>
                      <Link
                        to="/events"
                        className="text-white py-3 px-4 text-sm block text-left border-none font-roboto"
                        onClick={hideDropdown}
                      >
                        Events
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* Dropdown for Services */}
                  <div
                    className={classes.hamburger_link}
                    onClick={() => {
                      toggleDropdownMobile(HEADER_DROPDOWNS.services);
                    }}
                  >
                    <p className="!text-sm" onClick={hideDropdown}>
                      Services
                    </p>
                    <FontAwesomeIcon
                      className="mt-1.5"
                      icon={
                        visibleDropdown === HEADER_DROPDOWNS.services
                          ? faAngleUp
                          : faAngleDown
                      }
                    />
                  </div>
                  {visibleDropdown === HEADER_DROPDOWNS.services && (
                    <div className="pl-[32px]">
                      {/* <Link
                        to="/resources"
                        className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                        onClick={hideDropdown}
                      >
                        Resources
                      </Link> */}
                      <Link
                        to="/consulting-services"
                        className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300  font-roboto"
                        onClick={hideDropdown}
                      >
                        Consulting
                      </Link>
                      {/* <Link
                        to="/foster"
                        className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                        onClick={hideDropdown}
                      >
                        Foster
                      </Link> */}
                    </div>
                  )}

                  {/* Dropdown for Company */}
                  <div
                    className={classes.hamburger_link}
                    onClick={() => {
                      toggleDropdownMobile(HEADER_DROPDOWNS.company);
                    }}
                  >
                    <p className="!text-sm" onClick={hideDropdown}>
                      Company
                    </p>
                    <FontAwesomeIcon
                      className="mt-1.5"
                      icon={
                        visibleDropdown === HEADER_DROPDOWNS.company
                          ? faAngleUp
                          : faAngleDown
                      }
                    />
                  </div>
                  {visibleDropdown === HEADER_DROPDOWNS.company && (
                    <div className="pl-[32px]">
                      <Link
                        to="/about-us"
                        className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                        onClick={hideDropdown}
                      >
                        About Us
                      </Link>
                      <Link
                        to="/faq"
                        className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                        onClick={hideDropdown}
                      >
                        FAQ
                      </Link>
                      <Link
                        to="/our-team"
                        className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                        onClick={hideDropdown}
                      >
                        Our Team
                      </Link>
                      <Link
                        to="/blogs"
                        className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                        onClick={hideDropdown}
                      >
                        Blogs
                      </Link>
                      <Link
                        to="/shop"
                        className="text-white py-3 px-4 text-sm block text-left border-none font-roboto"
                        onClick={hideDropdown}
                      >
                        Shop
                      </Link>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {openBanner && (
        <div
          className={`${classes.affiliate_banner} w-full h-10 m-0 p-3 flex justify-center items-center fixed`}
        >
          <a href="/affiliates">
            Click here to view our affiliate program and earn money from
            donations!
          </a>
          <p onClick={() => setOpenBanner(false)}>X</p>
        </div>
      )}
    </>
  );
}

export default Header;
