import { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
} from "@mui/material";
import { fetchUpdatesByNP } from "../../api/Updates";
import {
  getNonprofitSubscription,
  updateNonProfitSubscription,
  nonprofitActivateSubscriptions,
} from "../../api/NonprofitSubscribe";

const UpdatesDropdown = ({ npData }) => {
  const [updates, setUpdates] = useState([]);
  const [subscriptionExists, setSubscriptionExists] = useState(false);
  const [subscriptionActive, setSubscriptionActive] = useState(false);

  const toggleSubscription = async () => {
    try {
      // check if an NP sub exists, if not, make one
      if (subscriptionExists === false) {
        await nonprofitActivateSubscriptions({
          nonprofit_id: npData.id,
        });
      } else {
        await updateNonProfitSubscription(npData.id);
      }
      setSubscriptionActive((prev) => !prev);
      window.location.reload();
    } catch (error) {
      console.error("There was a problem activating subscriptions", error);
    }
  };

  useEffect(() => {
    const getUpdates = async () => {
      const response = await fetchUpdatesByNP(npData.id);
      if (response) {
        setUpdates(response);
      }
    };

    getUpdates();
  }, []);

  useEffect(() => {
    const getNpSubscription = async () => {
      if (npData) {
        const response = await getNonprofitSubscription(npData.id);

        if (response.length === 0) {
          setSubscriptionActive(false);
          setSubscriptionExists(false);
        } else {
          setSubscriptionActive(response[0].activated ? true : false);
          setSubscriptionExists(true);
        }
      }
    };

    getNpSubscription();
  }, [npData]);

  return (
    <Accordion
      defaultExpanded
      sx={{
        boxShadow: "none",
        borderRadius: "0.75rem",
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon htmlColor="#FE994F" fontSize="large" />}
        sx={{
          backgroundColor: "#FFEDD5",
          borderRadius: "0.75rem 0.75rem 0 0",
          "&:not(.Mui-expanded)": {
            borderRadius: "0.75rem 0.75rem 0.75rem 0.75rem",
          },
          border: "2px solid #FE994F",
        }}
      >
        <div className="text-lg text-orange font-semibold pl-1">
          You have Pending Tasks
        </div>
      </AccordionSummary>
      <AccordionDetails
        className="border-x-2 border-b-2 rounded-b-xl text-start"
        sx={{ padding: "0rem" }}
      >
        <div className="flex items-center justify-between py-2 pl-10 pr-4">
          <h6 className="font-semibold">
            Allow Monthly Subscription for Donations?
          </h6>
          <Switch
            color="warning"
            checked={subscriptionActive}
            onChange={toggleSubscription}
          />
        </div>
        {updates.map((update, index) => (
          <h6 key={index} className="border-t border-t-grey-light px-10 py-2">
            {update.description}
          </h6>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
export default UpdatesDropdown;
