import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faStar,
  faCat,
  faDog,
  faHorse,
  faOtter,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getPaginatedCampaigns } from "../../../api/Campaigns";
import { urlifyName } from "../../shared/Utils/PageUrl";
import CampaignCard from "../CampaignCard/CampaignCard";
import Spinner from "../../shared/LoadingSpinner/Spinner";
import { states } from "states-us";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";

export const campaignExcerpt = (plainText) => {
  const EXCERPT_CHARS = 45;
  const EXCERPT_SENTENCES = 1;
  let sentences =
    plainText.split(".").slice(0, EXCERPT_SENTENCES).join(". ") + ".";
  return sentences.length > EXCERPT_CHARS
    ? sentences.substring(0, EXCERPT_CHARS) + "..."
    : sentences;
};

export default function CampaignSearch() {
  const [campaigns, setCampaigns] = useState([]);
  const [tag, setTag] = useState("");
  const [ageFilter, setAgeFilter] = useState("");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParam, setSearchParam] = useState(
    searchParams.get("search") || ""
  );
  const [page, setPage] = useState(1);
  const [IfDataExists, setIfDataExists] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [stateParam, setStateParam] = useState("");

  const getAllCampaigns = async (
    page,
    search,
    species,
    age,
    state,
    np_id = ""
  ) => {
    try {
      const response = await getPaginatedCampaigns(
        page,
        search,
        species,
        age,
        state,
        np_id
      );

      if (response) {
        setIsLoading(false);
      }

      if (page === 1) {
        setCampaigns(response.results);
      } else {
        setCampaigns([...campaigns, ...response.results]);
      }

      if (!response.next) {
        setIfDataExists(false);
      } else {
        setIfDataExists(true);
      }
    } catch (error) {
      console.error(`Could not get campaigns`, error);
    }
  };

  useEffect(() => {
    getAllCampaigns(page, "", "", "", "");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Handles searches
  const handleSearch = () => {
    setPage(1);
    getAllCampaigns(1, searchParam, tag, ageFilter, "", stateParam);
  };

  // Handles tag filter
  const handleTagChange = (e) => {
    setTag(e.target.value);
    setPage(1);
    getAllCampaigns(1, searchParam, e.target.value, ageFilter, "", stateParam);
  };

  // Handles age filter
  const handleAgeFilter = (e) => {
    setAgeFilter(e.target.value);
    setPage(1);
    getAllCampaigns(1, searchParam, tag, e.target.value, "", stateParam);
  };

  // Handles state filter
  const handleStateFilter = (e) => {
    setStateParam(e.target.value);
    setPage(1);
    getAllCampaigns(1, searchParam, tag, ageFilter, "", e.target.value);
  };

  // Redirects to the Campagin Feature page
  const handleClick = (campaign) => {
    navigate(
      `/campaigns/${urlifyName(campaign.nonprofit_name)}/${urlifyName(
        campaign.animal_name
      )}/${campaign.id}`
    );
  };

  // Handles showing more campaigns if available
  const handleLoadMoreButton = (e) => {
    e.preventDefault();
    getAllCampaigns(page + 1, searchParam, tag, ageFilter, "", stateParam);
    setPage(page + 1);
  };

  return (
    <>
      <div className="sm:grow flex flex-col justify-center items-center w-full !my-0 pt-40 bg-blue">
        <h1 className="text-white">Find Pets in Need</h1>
        <div className="flex gap-6 lg:gap-12 my-8">
          <label
            className={`flex flex-col w-12 cursor-pointer pb-1 border-b-[1px] ${
              tag === ""
                ? "text-yellow border-yellow"
                : "text-white border-blue"
            }`}
          >
            <input
              className="opacity-0 w-0 h-0"
              type="radio"
              name="species"
              id="species-all"
              value=""
              defaultChecked
              onChange={handleTagChange}
            />
            <FontAwesomeIcon className="text-3xl" icon={faStar} />
            <p className="m-0 mt-4">All</p>
          </label>
          <label
            className={`flex flex-col w-12 cursor-pointer pb-1 border-b-[1px] ${
              tag === "dog"
                ? "text-yellow border-yellow"
                : "text-white border-blue"
            }`}
          >
            <input
              className="opacity-0 w-0 h-0"
              type="radio"
              name="species"
              id="species-dog"
              value="dog"
              onChange={handleTagChange}
            />
            <FontAwesomeIcon className="text-3xl" icon={faDog} />
            <p className="m-0 mt-3.5">Dogs</p>
          </label>
          <label
            className={`flex flex-col w-12 cursor-pointer pb-1 border-b-[1px] ${
              tag === "cat"
                ? "text-yellow border-yellow"
                : "text-white border-blue"
            }`}
          >
            <input
              className="opacity-0 w-0 h-0"
              type="radio"
              name="species"
              id="species-cat"
              value="cat"
              onChange={handleTagChange}
            />
            <FontAwesomeIcon className="text-3xl" icon={faCat} />
            <p className="m-0 mt-3">Cats</p>
          </label>
          <label
            className={`flex flex-col w-12 cursor-pointer pb-1 border-b-[1px] ${
              tag === "farm"
                ? "text-yellow border-yellow"
                : "text-white border-blue"
            }`}
          >
            <input
              className="opacity-0 w-0 h-0"
              type="radio"
              name="species"
              id="species-farm"
              value="farm"
              onChange={handleTagChange}
            />
            <FontAwesomeIcon className="text-3xl" icon={faHorse} />
            <p className="m-0 mt-3.5">Farm</p>
          </label>
          <label
            className={`flex flex-col w-12 cursor-pointer pb-1 border-b-[1px] ${
              tag === "exotic"
                ? "text-yellow border-yellow"
                : "text-white border-blue"
            }`}
          >
            <input
              className="opacity-0 w-0 h-0"
              type="radio"
              name="species"
              id="species-exotic"
              value="exotic"
              onChange={handleTagChange}
            />
            <FontAwesomeIcon className="text-3xl" icon={faOtter} />
            <p className="m-0 mt-4">Exotic</p>
          </label>
        </div>
        <div className="flex flex-col lg:flex-row justify-between items-center my-0 gap-4 md:w-[60vw]">
          <div className="flex flex-1 items-center max-w-full w-full lg:w-auto h-9 border border-grey rounded-full overflow-hidden bg-white">
            <FontAwesomeIcon
              className="cursor-default text-grey ml-3 mr-2 text-sm"
              icon={faUsers}
            />
            <input
              type="text"
              placeholder="Type to search by animal name, location, nonprofit name, or animal breed."
              value={searchParam}
              onChange={(e) => setSearchParam(e.target.value)}
              className="flex-1 border-none outline-none text-xs px-2"
            />
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className="text-grey ml-2 mr-3 text-xs"
            />
          </div>
          <PrimaryButton onClick={handleSearch}>SEARCH</PrimaryButton>
        </div>
        <div className="flex flex-col md:flex-row py-5 gap-3 md:gap-4 mb-4">
          <select
            className="py-2 pl-4 cursor-pointer w-60 border border-grey rounded-full text-grey-dark1 text-xs bg-white outline-none flex-1"
            value={stateParam}
            onChange={handleStateFilter}
          >
            <option value="">State</option>
            {states.map((state) => (
              <option key={state.abbreviation} value={state.abbreviation}>
                {state.name}
              </option>
            ))}
          </select>
          <select
            className="py-2 pl-4 cursor-pointer w-60 border border-grey rounded-full text-grey-dark1 text-xs bg-white outline-none flex-1"
            value={ageFilter}
            onChange={handleAgeFilter}
          >
            <option value="" disabled hidden>
              Age
            </option>
            <option value="">All Ages</option>
            <option value="1">Newborn</option>
            <option value="2">Young</option>
            <option value="3">Adult</option>
            <option value="4">Senior</option>
            <option value="5">Unknown</option>
          </select>
        </div>
      </div>

      <div className="flex flex-col">
        {isLoading ? (
          <div className="flex flex-row mx-auto mt-16 w-full pb-24 justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="w-full flex max-w-md:flex-col flex-wrap justify-center max-w-md:items-center gap-12 max-w-md:gap-4 mt-8 mb-20 mx-auto py-0 px-20 max-w-md:px-4">
            {campaigns && campaigns.length > 0 ? (
              campaigns.map((campaign) => (
                <CampaignCard
                  key={campaign.id}
                  campaign={campaign}
                  handleClick={handleClick}
                />
              ))
            ) : (
              <p>Sorry, no matching results!</p>
            )}
          </div>
        )}

        {!isLoading && IfDataExists && (
          <button
            className="flex justify-center item-center mx-auto my-12 px-4 py-2 bg-blue hover:bg-blue-dark1 shadow-xl rounded-3xl text-white font-bold text-base "
            onClick={handleLoadMoreButton}
          >
            Load More
          </button>
        )}
      </div>
    </>
  );
}
