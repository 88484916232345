const NotFound = () => {
  return (
    <div
      className="flex items-center justify-center absolute inset-0"
      style={{ transform: "translateY(-10%)" }}
    >
      <h2 className="text-6xl">404 Error - Page Not Found</h2>
    </div>
  );
};

export default NotFound;
